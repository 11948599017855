<ng-template #addBuletinModal let-modal="close('Cross click')">
  <div class="modal-header">
    <h3 class="modal-title" [ngSwitch]="modalType">
      <ng-container *ngSwitchCase="'add'">
        Buletin nou
      </ng-container>
      <ng-container *ngSwitchCase="'atentionari'">
        Atenționări pentru fermier
      </ng-container>
    </h3>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        Anulează
      </button>
      <button class="btn btn-sm btn-success" (click)="onSubmitted()">
        <span [ngSwitch]="modalType">
          <ng-container *ngSwitchCase="'add'">
            Salvează noul buletin
          </ng-container>
          <ng-container *ngSwitchCase="'atentionari'">
            Crează pdf atenționări
          </ng-container>
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="buletinForm">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="data_cantarire">
                Data
              </label>
              <app-date-picker
                [selectedDate]="buletinForm.controls.data_cantarire.value"
                (dateChanged)="onDataCantarireChanged($event)"
                [required]="true"
                [submitted]="submitted"
              ></app-date-picker>
            </div>
          </div>

          <div class="col-sm-12" *ngIf="modalType == 'add'">
            <div class="form-group mb-3">
              <label for="tip_buletin">
                Tip buletin de control
              </label>

              <ng-select
                formControlName="tip_buletin"
                placeholder="Alege tip buletin de control"
                [ngClass]="{
                  'is-invalid': submitted && f.tip_buletin.errors
                }"
              >
                <ng-option value="7">Greutate la vârsta de 7 luni</ng-option>
                <ng-option value="12">Greutate la vârsta de 12 luni</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.tip_buletin.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.tip_buletin.errors }"
              >
                <div *ngIf="f.tip_buletin.errors.required">
                  Selectarea unei opțiuni este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isFermier" class="col-sm-12">
            <div class="form-group mb-3">
              <label for="operator_id">
                Operator control
              </label>
              <ng-select
                [items]="operatori"
                bindLabel="full_name"
                bindValue="id"
                formControlName="operator_id"
                placeholder="Alege operatorul"
                [ngClass]="{
                  'is-invalid': submitted && f.operator_id.errors
                }"
              >
              </ng-select>
              <div
                *ngIf="submitted && f.operator_id.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.operator_id.errors }"
              >
                <div *ngIf="f.operator_id.errors.required">
                  Selectarea unei opțiuni este obligatorie.
                </div>
              </div>
            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</ng-template>
