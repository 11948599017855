<div class="card" id="identificareAnimal" [formGroup]="animalForm">
  <h3 class="card-header">
    Identificare animal
    <ui-switch
      defaultBoColor="#dfdfdf"
      color="#00b19d"
      class="exp-state"
      size="medium"
      [checkedLabel]="'Activ'"
      [uncheckedLabel]="'Inactiv'"
      [checked]="status"
      (change)="changeStatus($event)"
      [ngClass]="{
        disabled: disableAllFields || !isSuperAdmin
      }"
    >
    </ui-switch>
  </h3>
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <div class="form-group mb-3">
          <label for="numarmatricol">
            Număr matricol*
          </label>
          <input
            type="text"
            placeholder="Adaugă număr matricol"
            class="numarmatricol form-control"
            formControlName="numarmatricol"
            (input)="
              animalForm.patchValue({
                numarmatricol: $event.target.value.toUpperCase()
              });
              numarmatricolChange($event)
            "
            *ngIf="
              modalType === 'add' ||
              modalType === 'edit' ||
              modalType === 'view' ||
              modalType === 'edit-mother' ||
              modalType === 'edit-father'
            "
            [ngClass]="{
              'is-invalid': submitted && f.numarmatricol.errors
            }"
            [readonly]="!isSuperAdmin && ['view', 'edit', 'edit-mother', 'edit-father'].includes(modalType)"
          />

          <ng-select
            [items]="searchingAnimals"
            bindLabel="fullDescription"
            bindValue="numarmatricol"
            *ngIf="modalType === 'add-mother' || modalType === 'add-father'"
            formControlName="numarmatricol"
            class="numarmatricol"
            [ngClass]="{ 'is-invalid': submitted && f.numarmatricol.errors }"
            (search)="searchAnimal($event)"
            (change)="selectedAnimal($event)"
          >
          </ng-select>

          <div
            *ngIf="submitted && f.numarmatricol.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.numarmatricol.errors }"
          >
            <div *ngIf="f.numarmatricol.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-auto">
        <div class="form-group mb-3">
          <label for="nume">
            Corespunde standardului rasei?
          </label>

          <div class="ml-2">
            <span
              *ngIf="animalForm.value.corespunde_standard == true"
              class="corespunde badge badge-success align-middle"
            >Corespunde</span>

            <span
              *ngIf="animalForm.value.corespunde_standard != true"
              class="corespunde badge badge-danger align-middle"
            >Nu corespunde</span>

            <button
              *ngIf="isAdmin || isOperator"
              type="button"
              class="btn btn-sm btn-outline-secondary ml-3 align-middle"
              (click)="clickCorespundeStandard()"
            >
              <i class="mdi mdi-square-edit-outline"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-auto mb-3" *ngIf="animalForm.value.hist_corespunde_standard">
        <table
          *ngIf="animalForm.value.hist_corespunde_standard.length"
          class="hist_corespunde_standard"
        >
          <tr>
            <th>Data</th>
            <th>Status vechi</th>
            <th>Status nou</th>
            <th>Observație</th>
            <th>Numele și username</th>
          </tr>
          <tr *ngFor="let item of animalForm.value.hist_corespunde_standard">
            <td>{{ item.data_dmy }}</td>
            <td>
              <span
                *ngIf="item.status_vechi == true"
                class="badge badge-success"
              >Corespunde</span>
              <span
                *ngIf="item.status_vechi != true"
                class="badge badge-danger"
              >Nu corespunde</span>
            </td>
            <td>
              <span
                *ngIf="item.status_nou == true"
                class="badge badge-success"
              >Corespunde</span>
              <span
                *ngIf="item.status_nou != true"
                class="badge badge-danger"
              >Nu corespunde</span>
            </td>
            <td class="observatie">{{ item.observatie }}</td>
            <td>{{ item.user_full_name }} ({{ item.username }})</td>
          </tr>
        </table>

      </div>

    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-3">
        <div class="form-group mb-3">
          <label for="datanastere">
            Data nașterii
          </label>
          <app-date-picker
            [selectedDate]="animalForm.controls.datanastere.value"
            [disabled]="isFermier || (!isSuperAdmin && (disableAllFields || evaluareGenetica || areFatare))"
            (dateChanged)="dataNasteriiChanged($event)"
          >
          </app-date-picker>
        </div>
      </div>

      <div class="col-sm-12 col-lg-3">
        <div class="form-group mb-3">
          <label for="sex">
            Sex*
          </label>
          <div
            class="radio mb-2 ml-2 custom-radio-group"
            [ngClass]="{ disabled: isFermier || (!isSuperAdmin && (disableAllFields || evaluareGenetica || areFatare)) }"
          >
            <input
              type="radio"
              name="sex"
              (change)="sexChange($event)"
              id="sex1"
              formControlName="sex"
              value="1"
              [ngClass]="{ 'is-invalid': submitted && f.sex.errors }"
            />
            <label for="sex1">
              Mascul
            </label>

            <input
              type="radio"
              name="sex"
              id="sex2"
              (change)="sexChange($event)"
              formControlName="sex"
              value="2"
              class="ml-3"
              [ngClass]="{ 'is-invalid': submitted && f.sex.errors }"
            />
            <label for="sex2">
              Femelă
            </label>
          </div>
          <div
            *ngIf="submitted && f.sex.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.sex.errors }"
          >
            <div *ngIf="f.sex.errors.required">
              Alegerea acestui câmp este obligatorie.
            </div>
          </div>
          <div
            *ngIf="invalidAnimalSex"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': invalidAnimalSex }"
          >
            <div *ngIf="invalidAnimalSex">
              Alegerea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="cols-sm-12 col-lg-3">
        <div class="form-group mb-3">
          <label for="nume">
            Nume
          </label>
          <input
            type="text"
            id="nume"
            placeholder="Adaugă nume animal"
            class="form-control"
            formControlName="nume"
            (change)="updateFields.emit($event)"
            (input)="
              animalForm.patchValue({
                nume: $event.target.value.toUpperCase()
              })
            "
            [ngClass]="{ disabled: disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-12 col-lg-3">
        <div class="form-group mb-3">
          <label for="color">
            Culoare
          </label>
          <ng-select
            bindLabel="color"
            bindValue="color"
            formControlName="color"
            placeholder="Alege culoare"
            [ngClass]="{ disabled: disableAllFields }"
          >
            <ng-option value="0">Alb</ng-option>
            <ng-option value="1">Rosu</ng-option>
            <ng-option value="2">Negru</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6" *ngIf="animalForm.value.sex == 2">
        <div class="form-group mb-3">
          <label for="categoriefemela">
            Categorie Femelă
          </label>
          <ng-select
            bindLabel="categoriefemela"
            bindValue="categoriefemela"
            placeholder="Alege categorie animal"
            formControlName="categoriefemela"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
            <ng-option value="0">Nespecificat</ng-option>
            <ng-option value="1">Vițea</ng-option>
            <ng-option value="2">Primipară</ng-option>
            <ng-option value="3">Multipară</ng-option>
            <ng-option value="4">Junincă</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-4">
        <div class="form-group mb-3">
          <label for="sistemreproductie">
            Obținut prin
          </label>
          <ng-select
            bindLabel="sistemreproductie"
            bindValue="sistemreproductie"
            placeholder="Produs prin"
            [clearable]="false"
            formControlName="sistemreproductie"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
            <ng-option value="0">Nespecificat</ng-option>
            <ng-option value="1">Însămânțare artificială</ng-option>
            <ng-option value="2">Montă naturală autorizată</ng-option>
            <ng-option value="3">Embriotransferuri</ng-option>
          </ng-select>
        </div>
      </div>

      <div
        class="col-sm-12 col-lg-4"
        *ngIf="animalForm.value.sistemreproductie === '3'"
      >
        <div class="form-group mb-3">
          <label for="mamapurtatoareid">
            Mamă purtătoare
          </label>

          <ng-select
            [items]="matricoleAnimal"
            (search)="searchMatricol($event, 2)"
            bindLabel="numarmatricol"
            bindValue="id"
            formControlName="mamapurtatoareid"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
            placeholder="Introduceți număr matricol"
            (clear)="clearMatricoleAnimal()"
            (blur)="clearMatricoleAnimal()"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="form-group mb-3">
          <label for="tipfatare">
            Tip fătare
          </label>
          <ng-select
            bindLabel="tipfatare"
            placeholder="Fătare"
            [clearable]="false"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
            formControlName="tipfatare"
          >
            <ng-option value="0">Nespecificat</ng-option>
            <ng-option value="1">Simplă</ng-option>
            <ng-option value="2">Gemelară</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h4>Teste ADN</h4>

        <div class="mb-2" *ngIf="! test_adn.controls.length">
          Nu are Teste ADN
        </div>
      </div>
    </div>

    <ng-container formArrayName="test_adn">
      <ng-container *ngFor="let testADNForm of test_adn.controls; let index = index">
        <ng-container [formGroup]="testADNForm">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-auto">
              <label for="afiseaza_in_cz" *ngIf="index == 0">
                CZ
              </label>
              <input
                type="checkbox"
                (change)="changeAfiseazaInCZ(testADNForm)"
                formControlName="afiseaza_in_cz"
                class="afiseaza_in_cz my-2"
                [ngClass]="{
                  'disabled': testADNForm.controls.readonly.value
                }"
              />
            </div>

            <div class="col-sm-12 col-md-auto">
              <label for="metoda" *ngIf="index == 0">
                Metoda
              </label>
              <ng-select
                bindLabel="metoda"
                bindValue="metoda"
                formControlName="metoda"
                placeholder="Metoda"
                class="metoda_test_adn"
                [ngClass]="{
                  'disabled': testADNForm.controls.readonly.value
                }"
              >
                <ng-option value="ADN">ADN</ng-option>
                <ng-option value="STR">STR</ng-option>
                <ng-option value="SNP">SNP</ng-option>
              </ng-select>
            </div>

            <div class="col-sm-12 col-md-auto">
              <label for="tip" *ngIf="index == 0">
                Tip
              </label>
              <ng-select
                bindLabel="tip"
                bindValue="tip"
                formControlName="tip"
                placeholder="Tip"
                class="tip_test_adn"
                [ngClass]="{
                  'disabled': testADNForm.controls.readonly.value
                }"
              >
                <ng-option value="profil">profil</ng-option>
                <ng-option value="verificare tata">verificare tata</ng-option>
                <ng-option value="verificare mama">verificare mama</ng-option>
                <ng-option value="verificare tata si mama">verificare tata si mama</ng-option>
                <ng-option value="test genomic">test genomic</ng-option>
                <ng-option value="atribuirea rasei">atribuirea rasei</ng-option>
                <ng-option value="reconstituire tata">reconstituire tata</ng-option>
                <ng-option value="reconstituire mama">reconstituire mama</ng-option>
                <ng-option value="profil + verificare tata">profil + verificare tata</ng-option>
                <ng-option value="profil + verificare mama">profil + verificare mama</ng-option>
                <ng-option value="profil + verificare tata si mama">profil + verificare tata si mama</ng-option>
                <ng-option value="profil + reconstituire tata">profil + reconstituire tata</ng-option>
                <ng-option value="profil + reconstituire mama">profil + reconstituire mama</ng-option>
              </ng-select>
            </div>

            <div class="col-sm-12 col-md-auto">
              <label for="id_rg" *ngIf="index == 0">
                I.D. R.G.
              </label>
              <input
                type="text"
                id="id_rg"
                placeholder="I.D. R.G."
                formControlName="id_rg"
                class="id_test_adn form-control"
                [readonly]="testADNForm.controls.readonly.value"
              />
            </div>

            <div class="col-sm-12 col-md-auto">
              <label for="numar_adn" *ngIf="index == 0">
                Număr ADN
              </label>
              <input
                type="text"
                id="numar_adn"
                placeholder="Număr ADN"
                formControlName="numar_adn"
                class="numar_test_adn form-control"
                [readonly]="testADNForm.controls.readonly.value"
              />
            </div>

            <div class="col-sm-12 col-md-auto d-flex align-items-end">
              <i
                *ngIf="!testADNForm.controls.readonly.value"
                class="fas fa-times-circle h3 text-danger"
                ngbTooltip="Șterge"
                placement="bottom"
                (click)="clickStergeTestADN(index)"
              ></i>
            </div>

          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div
      *ngIf="isAdmin || isOperator"
      class="row mb-3"
    >
      <div class="col-sm-12">
        <button
          class="btn btn-outline-success btn-sm"
          type="button"
          (click)="adaugaTestADN()"
        >
          <i class="fa fa-plus-circle"></i>
          Adaugă Test ADN
        </button>
      </div>
    </div>

    <div class="row">
      <div
        *ngIf="isAdmin || isOperator"
        class="col-sm-12 col-md-6"
      >
        <div class="form-group mb-3">
          <app-upload-fisier
            [ngClass]="{'disabled' : disableAllFields}"
            (ApiResponse)="uploadServerResponse($event)"
          >
          </app-upload-fisier>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <h5>Fișiere Test ADN</h5>

        <ng-container formArrayName="test_adn_files">
          <ng-container *ngFor="let testADNFileForm of test_adn_files.controls; let index = index">
            <div>
              <button
                type="button"
                class="btn p-0 text-blue font-weight-bold"
                (click)="downloadFisierTestADN($event, testADNFileForm)"
              >
                <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                {{ testADNFileForm.value.uploaded_file_name }}
              </button>
              <button
                *ngIf="isAdmin || isOperator"
                type="button"
                class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                ngbTooltip="Șterge fișier"
                placement="right"
                (click)="clickStergeFisierTestADNModal(index)"
              >
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group mb-3">
          <label for="exploatatieid">
            Exploatație
          </label>
          <ng-select
            [items]="holdingsNames"
            bindLabel="name"
            bindValue="id"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
            placeholder="Alege exploatație"
            formControlName="exploatatieid"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }} - {{ item.code }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              {{ item.name }} - {{ item.code }}
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-sm-11">
        <div class="form-group mb-3">
          <label for="crescatorid">
            Crescător animal
          </label>
          <ng-select
            [items]="holdingsNameForOwners"
            bindLabel="name"
            bindValue="id"
            placeholder="Alege crescător animale"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
            formControlName="crescatorid"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }} - {{ item.code }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              {{ item.name }} - {{ item.code }}
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-sm-1 add-holding-btn">
        <i
          class="fas fa-plus-circle"
          ngbTooltip="Creează exploatație"
          placement="bottom"
          (click)="openAddHoldingModal()"
          [ngClass]="{
            disabled:
              animalForm.status === 'DISABLED' ||
              disableAllFields ||
              !isAdmin
          }"
        ></i>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="form-group mb-3">
          <label for="dataintrareinexploatatie">
            Dată intrare in exploatație
          </label>
          <app-date-picker
            [selectedDate]="animalForm.controls.dataintrareinexploatatie.value"
            [disabled]="isFermier || disableAllFields"
            [invalid]="dateError"
            (dateChanged)="dataIntrareExploatatieChanged($event)"
          >
          </app-date-picker>
          <div
            *ngIf="dateError"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': dateError }"
          >
            <div *ngIf="dateError">
              Data introdusă nu poate fi mai mică decât data de naștere a
              animalului selectat.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="form-group custom-control">
          <label class="mt-1">Animal contractat</label>
          <ui-switch
            defaultBoColor="#dfdfdf"
            color="#4567bf"
            class="contractat"
            size="medium"
            [formControl]="animalForm.controls['contractat']"
            [checkedLabel]="'Da'"
            [uncheckedLabel]="'Nu'"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
          </ui-switch>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-update-holding
  [open]="openAddEditModal"
  (notifyClose)="closeAddUpdateModal($event)"
  [modalType]="'add'"
  [holdingData]="holdingData"
  (callbackResult)="updateHoldingsList($event)"
>
</app-add-update-holding>

<ng-template #stergeTestADNModal let-modal="close">
  <div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="modal('Cross click')">
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text">
      <h3 class="confirm-text">Șterge Testul ADN?</h3>
    </div>

    <div class="separator"></div>
    <div class="container-fluid confirm-modal text-center">
      <button class="btn btn-warning mr-4" (click)="modal('Cross click')">
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareStergeTestADN(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>

<ng-template #stergeFisierTestADNModal let-modal="close">
  <div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="modal('Cross click')">
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text">
      <h3 class="confirm-text">Șterge Fișierul?</h3>
    </div>

    <div class="separator"></div>
    <div class="container-fluid confirm-modal text-center">
      <button class="btn btn-warning mr-4" (click)="modal('Cross click')">
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareStergeFisierTestADNModal(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>

<ng-template #corespundeStandardModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Modificare: Corespunde Standard
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">

      <div class="row">
        <div class="col-12">

        Modifică starea animalului {{ animalForm.value.numarmatricol }}
        <br>

        din

        <span
          *ngIf="animalForm.value.corespunde_standard == true"
          class="badge badge-success"
        >Corespunde</span>
        <span
          *ngIf="animalForm.value.corespunde_standard != true"
          class="badge badge-danger"
        >Nu corespunde</span>

        în

        <span
          *ngIf="corespundeStandardSwitch == true"
          class="badge badge-success"
        >Corespunde</span>
        <span
          *ngIf="corespundeStandardSwitch != true"
          class="badge badge-danger"
        >Nu corespunde</span>

        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group mb-3">
            <label>
              Observație (opțional)
            </label>

            <textarea
              [(ngModel)]="observatie"
              rows="3"
              maxlength="200"
              class="form-control text-area"
            ></textarea>
          </div>
        </div>
      </div>

      <div
        *ngIf="!arePoze"
        class="text-danger font-weight-bold"
      >
        Animalul nu are poze
      </div>

      <div class="row p-2">
        <div class="col-sm-12 text-right">
          <button
            class="btn btn-dark mr-2"
            type="button"
            (click)="modal('Cross click')"
          >
            Anulează
          </button>
          <button
            class="btn btn-success"
            type="button"
            (click)="modal('Cross click'); confirmareCorespundeStandard(true)"
            [disabled]="!isSuperAdmin && !arePoze"
          >
            Actualizează
          </button>
        </div>
      </div>

    </div>
  </div>

</ng-template>
