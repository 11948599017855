<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="adeverintaApartenentaTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  *ngIf="!isAutoritateJudeteana && !isContabil"
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openAddAdeverintaModal('add')"
                  [disabled]="loadingDataSpinner"
                >
                  <i class="fe-save"></i>
                  Solicită adeverință
                </button>
                <button
                  *ngIf="!isAutoritateJudeteana && !isContabil"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #adeverinteApartenentaTable>
            <table
              id="adeverinte-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="comp_code" (sort)="onSort($event)">
                    Cod exploatație
                  </th>
                  <th sortable="comp_name" (sort)="onSort($event)">
                    Nume exploatație
                  </th>
                  <th sortable="numar" (sort)="onSort($event)">
                    Număr
                  </th>
                  <th sortable="data" (sort)="onSort($event)">
                    Data
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    Status
                  </th>
                  <th sortable="autor" (sort)="onSort($event)">
                    Autor
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="adeverintaApartenentaTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o adeverință de apartenență în urma
                căutării dvs.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let adeverinta of adeverinteApartenenta">
                  <td>{{ adeverinta.comp_code }}</td>
                  <td>{{ adeverinta.comp_name }}</td>
                  <td>
                    <input
                      type="number"
                      class="form-control read-only-form"
                      [readonly]="!isSuperAdmin && adeverinta.status != 1"
                      (change)="adeverinta.numar = $event.target.value"
                      [value]="adeverinta.numar"
                    />
                  </td>
                  <td>
                    <app-date-picker
                      [selectedDate]="adeverinta.data"
                      (dateChanged)="adeverinta.data = $event"
                      [disabled]="!isSuperAdmin && adeverinta.status != 1"
                    ></app-date-picker>
                  </td>
                  <td [ngSwitch]="adeverinta.status">
                    <span *ngSwitchCase="1">Ciornă</span>
                    <span *ngSwitchCase="2">Validată</span>
                    <span *ngSwitchCase="3">Invalidată</span>
                    <span *ngSwitchCase="4">Printată</span>
                    <span *ngSwitchCase="5">Anulată</span>
                  </td>
                  <td>{{ adeverinta.autor }}</td>

                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        *ngIf="adeverinta.status === 2"
                        class="btn btn-sm btn-success"
                        title="Descarca"
                        (click)="
                          downloadAdeverinta(adeverinta)
                        "
                      >
                        Descarcă
                      </button>
                      <button
                        *ngIf="adeverinta.status === 1 && isAdmin"
                        class="btn btn-sm btn-info"
                        title="Valideaza"
                        (click)="
                          openConfirmActionModal(
                            adeverinta,
                            'valideaza-adeverinta'
                          )
                        "
                      >
                        Validează
                      </button>

                      <button
                        *ngIf="isSuperAdmin || (isAdmin && adeverinta.status == 1)"
                        class="btn btn-sm btn-danger"
                        (click)="
                          openConfirmActionModal(
                            adeverinta,
                            'sterge-adeverinta'
                          )
                        "
                      >
                        Șterge
                      </button>

                      <button
                        *ngIf="
                          adeverinta.status === 1 || adeverinta.status === 3
                        "
                        class="btn btn-sm btn-success"
                        title="Vizualizeaza"
                        (click)="openViewAllowanceModal(adeverinta)"
                      >
                        Vizualizează
                      </button>
                      <button
                        *ngIf="adeverinta.status === 4"
                        class="btn btn-sm btn-info"
                        (click)="
                          downloadAdeverinta(adeverinta)
                        "
                      >
                        Descarcă din nou
                      </button>
                      <button
                        *ngIf="adeverinta.status === 4 && isAdmin"
                        class="btn btn-sm btn-danger"
                        title="Anuleaza"
                        (click)="
                          openConfirmActionModal(
                            adeverinta,
                            'anuleaza-adeverinta'
                          )
                        "
                      >
                        Anulează
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="adeverintaApartenentaTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ adeverintaApartenentaTableService.startIndex }} la
                {{ adeverintaApartenentaTableService.endIndex }} din
                {{ adeverintaApartenentaTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="adeverinteApartenentaLength"
                  [(page)]="adeverintaApartenentaTableService.page"
                  [pageSize]="adeverintaApartenentaTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-add-update-allowance
  [open]="openAddEditModal"
  (notifyClose)="closeAddEditModal($event)"
  (callbackResult)="getCallbackResult($event)"
  [addEditModalType]="addEditModalType"
  [currentHolding]="currentHolding"
>
</app-add-update-allowance>

<app-view-allowance
  [open]="openViewModal"
  (notifyClose)="closeViewModal($event)"
  (callbackResult)="getCallbackResult($event)"
  [vaciRaseCarne]="vaciRaseCarne"
  [vaciMetise]="vaciMetise"
  [tauriCarne]="tauriCarne"
  [tineretRaseCarne]="tineretRaseCarne"
  [tineretMetis]="tineretMetis"
  [aboutAdeverintaData]="aboutAdeverintaData"
>
</app-view-allowance>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="confirmType"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
