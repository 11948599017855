<ng-template #addEditContractModal let-modal="close">
  <div class="modal-header row" [ngSwitch]="addEditModalType">
    <div class="col-sm-12 col-sm-4 col-md-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Contract nou
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează contract
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'view'">
        Vizualizează contract
      </h3>
    </div>

    <div class="col-sm-12 col-sm-8 col-md-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-dark"
          (click)="modal('Cross click')"
          autofocus="false"
        >
          <span *ngIf="!permisiuneEditare; else anuleaza">Închide</span>
          <ng-template #anuleaza>
            <span>Anulează</span>
          </ng-template>
        </button>

        <button
          *ngIf="permisiuneEditare"
          class="btn btn-sm btn-success"
          (click)="onSubmitted()"
        >
          <span *ngIf="addEditModalType === 'add'; else btnTxt">
            Salvează noul contract
          </span>
          <ng-template #btnTxt>
            <span>
              Actualizează contract
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="contractForm">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="status">
                Status contract
              </label>
              <ng-select
                formControlName="contr_status"
                placeholder="Adaugă status"
                [clearable]="false"
                [ngClass]="{
                  'is-invalid': submitted && f.contr_status.errors,
                  'disabled': cannotEdit,
                }"
              >
                <ng-option [value]="0">Inactiv</ng-option>
                <ng-option [value]="3">Activ</ng-option>
                <ng-option [value]="4">Reziliat</ng-option>
                <ng-option [value]="5">Suspendat</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.contr_status.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.contr_status.errors }"
              >
                <div *ngIf="f.contr_status.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="numar">
                Număr contract*
              </label>
              <input
                type="number"
                id="numar"
                placeholder="Adaugă numar"
                class="form-control"
                formControlName="contr_nr"
                [ngClass]="{ 'is-invalid': submitted && f.contr_nr.errors }"
                [readonly]="cannotEdit"
                (input)="
                  contractForm.patchValue({
                    contr_nr: $event.target.value.toUpperCase()
                  })
                "
              />
              <div
                *ngIf="submitted && f.contr_nr.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.contr_nr.errors }"
              >
                <div *ngIf="f.contr_nr.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="contr_type">
                Tip contract*
              </label>
              <ng-select
                bindLabel="contr_type"
                bindValue="contr_type"
                formControlName="contr_type"
                placeholder="Adaugă tip contract"
                (change)="changedContractType($event)"
                [ngClass]="{
                  'is-invalid': submitted && f.contr_type.errors,
                  'disabled': cannotEdit,
                }"
              >
                <ng-option [value]="1">Registru Genealogic (RG)</ng-option>
                <ng-option [value]="2"
                  >Controlul Performanțelor Producției (CPP)</ng-option
                >
              </ng-select>
              <div
                *ngIf="submitted && f.contr_type.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.contr_type.errors }"
              >
                <div *ngIf="f.contr_type.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="data">
                Data contract*
              </label>
              <app-date-picker
                [selectedDate]="contractForm.controls.contr_date.value"
                (dateChanged)="dateChanged($event, contractForm.controls.contr_date)"
                [required]="true"
                [disabled]="cannotEdit"
                [submitted]="submitted"
              >
              </app-date-picker>
            </div>
          </div>

          <div class="d-flex flex-wrap col-sm-12">
            <div class="mb-3">
              <label class="mr-2">
                Contract
              </label>

              <span
                *ngIf="contractForm.controls.file_id_contract.value"
                class="cursor-pointer p-0 text-blue font-weight-bold"
                (click)="downloadFileContract()"
              >
                <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                {{ fileNameContract }}
              </span>

              <button
                *ngIf="contractForm.controls.file_id_contract.value && !cannotEdit"
                type="button"
                class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                ngbTooltip="Șterge fișier"
                placement="right"
                (click)="deleteFileContract()"
              >
              </button>

              <app-upload-fisier
                *ngIf="!contractForm.controls.file_id_contract.value && permisiuneCreare"
                (ApiResponse)="uploadContract($event)"
              >
              </app-upload-fisier>
            </div>
          </div>

          <div class="d-flex flex-wrap col-sm-12">
            <div>
              <label class="mr-2">
                Atestat Producător
              </label>

              <span
                *ngIf="atestatProducatorControls.file_id.value"
                class="cursor-pointer p-0 text-blue font-weight-bold"
                (click)="downloadFileAtestatProducator()"
              >
                <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                {{ atestatProducatorControls.file_name.value }}
              </span>

              <button
                *ngIf="atestatProducatorControls.file_id.value && permisiuneEditare"
                type="button"
                class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                ngbTooltip="Șterge fișier"
                placement="right"
                (click)="deleteFileAtestatProducator()"
              >
              </button>

              <app-upload-fisier
                *ngIf="!atestatProducatorControls.file_id.value && permisiuneEditare"
                (ApiResponse)="uploadAtestatProducator($event)"
              >
              </app-upload-fisier>
            </div>

            <div class="ml-3">
              <label>
                Dată valabilitate început
              </label>

              <app-date-picker
                [selectedDate]="atestatProducatorControls.data_valabilitate_inceput.value"
                (dateChanged)="dateChanged($event, atestatProducatorControls.data_valabilitate_inceput)"
                [disabled]="!permisiuneEditare"
              >
              </app-date-picker>
            </div>

            <div class="ml-3">
              <label>
                Dată valabilitate sfârșit
              </label>

              <app-date-picker
                [selectedDate]="atestatProducatorControls.data_valabilitate_sfarsit.value"
                (dateChanged)="dateChanged($event, atestatProducatorControls.data_valabilitate_sfarsit)"
                [disabled]="!permisiuneEditare"
              >
              </app-date-picker>
            </div>
          </div>

          <div
            formArrayName="contr_races"
            class="col-12"
            *ngFor="
              let item of formData.controls;
              let i = index;
              let last = last;
              let first = first
            "
          >
            <div class="row" [formGroupName]="i">
              <div class="col-8">
                <div class="form-group">
                  <label for="rasaid">
                    Rasă*
                  </label>
                  <ng-select
                    [items]="raseAnimal"
                    bindLabel="name"
                    bindValue="idacbcr_race"
                    placeholder="Alege rasa"
                    formControlName="rasaid"
                    [ngClass]="{
                      'is-invalid': submitted && item.get('rasaid').errors,
                      'disabled': cannotEdit
                    }"
                  >
                  </ng-select>
                  <div
                    *ngIf="submitted && item.get('rasaid').errors"
                    class="invalid-feedback d-block"
                  >
                    <div *ngIf="item.get('rasaid').errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-3 buttons-col">
                <button
                  *ngIf="last && contractForm.controls.contr_type.value === 2 && !cannotEdit"
                  type="button"
                  class="btn btn-sm btn-success"
                  (click)="addAnimalBreed()"
                  [disabled]="formData.controls.length === raseAnimal.length"
                >
                  <i class="fas fa-plus-circle"></i>
                  Adaugă rasă
                </button>

                <button
                  *ngIf="!first && contractForm.controls.contr_type.value === 2 && !cannotEdit"
                  type="button"
                  class="btn btn-sm btn-danger"
                  (click)="removeAnimalBreed(i)"
                >
                  <i class="fas fa-minus-circle"></i>
                  Șterge rasă
                </button>
              </div>

              <div class="col-sm-12 col-lg-3 nr_capete">
                <div class="form-group mb-3 position-relative">
                  <label for="nrfemelecuratcertificat">
                    Nr. femele rasă pură
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend w-100">
                      <input
                        type="number"
                        placeholder="Nr. femele rasă pură"
                        class="form-control"
                        formControlName="nrfemelecuratcertificat"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            item.get('nrfemelecuratcertificat').errors
                        }"
                        [readonly]="cannotEdit"
                        (input)="$event.target.value = $event.target.value.toUpperCase()"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="
                      submitted && item.get('nrfemelecuratcertificat').errors
                    "
                    class="invalid-feedback"
                    [ngClass]="{
                      'd-block':
                        submitted && item.get('nrfemelecuratcertificat').errors
                    }"
                  >
                    <div
                      *ngIf="
                        item.get('nrfemelecuratcertificat').errors.required
                      "
                    >
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-lg-3 nr_capete">
                <div class="form-group mb-3 position-relative">
                  <label for="nrmasculicuratcertificat">
                    Nr. masculi rasă pură
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend w-100">
                      <input
                        type="number"
                        placeholder="Nr. masculi rasă pură"
                        class="form-control"
                        formControlName="nrmasculicuratcertificat"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            item.get('nrmasculicuratcertificat').errors
                        }"
                        [readonly]="cannotEdit"
                        (input)="$event.target.value = $event.target.value.toUpperCase()"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="
                      submitted && item.get('nrmasculicuratcertificat').errors
                    "
                    class="invalid-feedback"
                    [ngClass]="{
                      'd-block':
                        submitted && item.get('nrmasculicuratcertificat').errors
                    }"
                  >
                    <div
                      *ngIf="
                        item.get('nrmasculicuratcertificat').errors.required
                      "
                    >
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-lg-3 nr_capete">
                <div class="form-group mb-3 position-relative">
                  <label for="nrtineretrasapura">
                    Nr. tineret rasă pură
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend w-100">
                      <input
                        type="number"
                        placeholder="Nr. tineret rasă pură"
                        class="form-control"
                        formControlName="nrtineretrasapura"
                        [ngClass]="{
                          'is-invalid':
                            submitted && item.get('nrtineretrasapura').errors
                        }"
                        [readonly]="cannotEdit"
                        (input)="$event.target.value = $event.target.value.toUpperCase()"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="submitted && item.get('nrtineretrasapura').errors"
                    class="invalid-feedback"
                    [ngClass]="{
                      'd-block':
                        submitted && item.get('nrtineretrasapura').errors
                    }"
                  >
                    <div *ngIf="item.get('nrtineretrasapura').errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-lg-3 nr_capete">
                <div class="form-group mb-3 position-relative">
                  <label for="nrmetis">
                    Nr. femele înregistrate în sect. suplimentară
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend w-100">
                      <input
                        type="number"
                        placeholder="Nr. femele înregistrate în sect. suplimentară"
                        class="form-control"
                        formControlName="nrmetis"
                        [ngClass]="{
                          'is-invalid': submitted && item.get('nrmetis').errors
                        }"
                        [readonly]="cannotEdit"
                        (input)="$event.target.value = $event.target.value.toUpperCase()"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="submitted && item.get('nrmetis').errors"
                    class="invalid-feedback"
                    [ngClass]="{
                      'd-block': submitted && item.get('nrmetis').errors
                    }"
                  >
                    <div *ngIf="item.get('nrmetis').errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-acte-aditionale
          [acte_aditionale]="contractForm.controls.acte_aditionale"
          [email_cont_exploatatie]="email_cont_exploatatie"
          (addActeAditionale)="checkContractEdit()"
        >
        </app-acte-aditionale>

        <div>
          <button
            *ngIf="permisiuneCreare"
            class="btn btn-success btn-sm"
            type="button"
            (click)="creazaActAditional()"
          >
            Crează Act Adițional
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>
