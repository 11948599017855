<div class="card" id="pedigree" [formGroup]="animalForm">
  <h3 class="card-header">Pedigree</h3>
  <div class="card-body">

    <div class="row">
      <div class="chart">
        <h4 class="mb-3">Arbore genealogic:</h4>
        <p-organizationChart
          [(value)]="animalFamilyTree"
          selectionMode="single"
          [preserveSpace]="false"
          styleClass="company"
          (onNodeSelect)="onNodeSelect($event)"
        >
          <ng-template let-node pTemplate="animal">
            <div>
              <div class="node-header ui-corner-top">
                <span>{{ node.numarmatricol }}</span><br>
                <span *ngIf="node.codtaur">( {{ node.codtaur }} )</span>
                <div><i>{{ node.nume }}</i></div>
                <div class="separator"></div>
                <div>{{ node.sex }}</div>
              </div>
              <div class="node-content">
                <div>{{ node.datanastere }}</div>
                <div class="text-muted">
                  {{ node.sectiune_rg }}
                </div>
                <div>{{ node.rasa }}</div>
              </div>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="parent">
            <div>
              <a
                *ngIf="
                  (node.father === 'T' || node.father === 'M')
                  && !areFatare
                  &&
                  (
                    isSuperAdmin
                    ||
                    (
                      !isFermier
                      && !disableAllFields
                      && !animalForm.value.are_certificat_zootehnic
                      && !evaluareGenetica
                    )
                  )"
                href="javascript:void(0);"
                (click)="deleteParent($event, node)"
              >
                <i class="mdi mdi-close-circle delete-parent"></i>
              </a>
              <div class="node-header">
                <span>{{ node.numarmatricol }}</span><br>
                <span *ngIf="node.codtaur">( {{ node.codtaur }} )</span>
                <div><i>{{ node.nume }}</i></div>
                <div class="separator"></div>
                <div>{{ node.sex }}</div>
              </div>
              <div class="node-content">
                <div>{{ node.datanastere }}</div>
                <div
                  *ngIf="node.sectiune_rg"
                  class="text-muted"
                >
                  {{ node.sectiune_rg }}
                </div>
                {{ node.father }},
                {{ node.rasa }}
              </div>
            </div>
          </ng-template>
        </p-organizationChart>
      </div>
    </div>

    <div
      class="separator mt-3 mb-3">
    </div>

    <div
      formArrayName="rase"
      *ngFor="let item of formData.controls; let i = index; let last = last; let first = first"
    >
      <div class="row" [formGroupName]="i">

        <div class="col-sm-12 col-lg-6">
          <div class="form-group mb-3">
            <label for="rasa">
              Rasă*
            </label>
            <ng-select
              id="raceid"
              [items]="raseAnimal"
              bindLabel="name"
              bindValue="id"
              placeholder="Alege rasa"
              formControlName="raceid"
              (change)="changeRasaAnimal()"
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  item.get('raceid').invalid,
                'disabled' : isFermier || disableAllFields || autoRaseDisabled
              }"
            >
            </ng-select>
              <div
                *ngIf="submitted && item.get('raceid').errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && item.get('raceid').errors }"
              >
                <div *ngIf="item.get('raceid').errors.required">
                  Alegerea unei rase este obligatorie.
                </div>
              </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-3">
          <div class="form-group mb-3 position-relative">
            <label for="rasa_val">
              Procent*
            </label>
            <div class="input-group">
              <div class="input-group-prepend w-100">
                <input
                  type="number"
                  min="0"
                  max="100"
                  placeholder="Procent %"
                  class="form-control"
                  formControlName="val"
                  (change)="changeRasaAnimal()"
                  (input)="rasaVal($event, i); $event.target.value = $event.target.value.toUpperCase()"
                  [ngClass]="{
                    'is-invalid': submitted && item.get('val').errors,
                    'disabled' : isFermier || disableAllFields || autoRaseDisabled
                  }"
                />
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
            </div>
            <div
              *ngIf="submitted && item.get('val').errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && item.get('val').errors }"
            >
              <div *ngIf="item.get('val').errors.required">
                Completarea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-3 buttons-col">
          <button
            *ngIf="last && !isFermier && !disableAllFields && !disableRasa && !autoRaseDisabled"
            type="button"
            class="btn btn-sm btn-success"
            (click)="addAnimalBreed()"
          >
            <i class="fas fa-plus-circle"></i>
            Adaugă rasă
          </button>

          <button
            *ngIf="!first && !isFermier && !disableAllFields && !autoRaseDisabled"
            type="button"
            class="btn btn-sm btn-danger"
            (click)="removeAnimalBreed(i)"
          >
            <i class="fas fa-minus-circle"></i>
            Șterge rasă
          </button>

        </div>

      </div>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12 col-lg-4">
          <div class="form-group">
            <label for="are_certificat_zootehnic">
              Are certificat zootehnic?
            </label>
            <div
              class="radio mb-2 custom-radio-group"
              [ngClass]="{ disabled: !isAdmin || disableAllFields }"
            >
              <input
                type="radio"
                name="are_certificat_zootehnic"
                formControlName="are_certificat_zootehnic"
                id="are_certificat_zootehnic1"
                [value]="true"
                [ngClass]="{
                  'is-invalid': submitted && f.are_certificat_zootehnic.errors,
                  'disabled': isNumarMatricol === false
                }"
              />
              <label for="are_certificat_zootehnic1">
                Da
              </label>

              <input
                type="radio"
                name="are_certificat_zootehnic"
                id="are_certificat_zootehnic2"
                formControlName="are_certificat_zootehnic"
                class="ml-5"
                [value]="false"
                [ngClass]="{
                  'is-invalid': submitted && f.are_certificat_zootehnic.errors
                }"
              />
              <label for="are_certificat_zootehnic2">
                Nu
              </label>
            </div>

            <div *ngIf="isNumarMatricol === true">
              <div class="row" *ngFor="let message of eligibilitateMessage">
                <h4 class="error-message">{{ message }}</h4>
              </div>
            </div>
            <div
              *ngIf="submitted && f.are_certificat_zootehnic.errors"
              class="invalid-feedback"
              [ngClass]="{
                'd-block': submitted && f.are_certificat_zootehnic.errors
              }"
            >
              <div *ngIf="f.are_certificat_zootehnic.errors.required">
                Alegerea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<app-add-update-animal
  [open]="openAddEditModal"
  [modalType]="modalType"
  [animalData]="animalData"
  [matricolChild]="animalForm.value.numarmatricol"
  (notifyClose)="closeAddUpdateModal($event)"
>
</app-add-update-animal>
