<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="rapoarteFacturiEmiseTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Filtrare
                </button>

                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                  [disabled]="loadingDataSpinner || rapoarteFacturiEmiseLength === 0 || !isDataFiltered"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #movesTable>
            <table
              id="moves-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="cif" (sort)="onSort($event)">
                    CIF
                  </th>
                  <th sortable="code" (sort)="onSort($event)">
                    Cod exploatație
                  </th>
                  <th sortable="data" (sort)="onSort($event)">
                    Data
                  </th>
                  <th sortable="factura" (sort)="onSort($event)">
                    Factura
                  </th>
                  <th sortable="name" (sort)="onSort($event)">
                    Nume
                  </th>
                  <th sortable="stare" (sort)="onSort($event)">
                    Stare
                  </th>
                  <th sortable="tipfactura" (sort)="onSort($event)">
                    Tip factura
                  </th>
                  <th sortable="valfactura" (sort)="onSort($event)">
                    Valoare factura
                  </th>
                  <th sortable="valtva" (sort)="onSort($event)">
                    Valoare TVA
                  </th>
                </tr>
              </thead>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="rapoarteFacturiEmiseTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o factură emisă.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let raport of rapoarteFacturiEmise" [ngClass]="{cancelled : raport.stare === 'anulata'}">
                  <td> {{raport.cif}} </td>
                  <td> {{raport.code}} </td>
                  <td> {{raport.data}} </td>
                  <td> {{raport.factura}} </td>
                  <td> {{raport.name}} </td>
                  <td> {{raport.stare}} </td>
                  <td> {{raport.tipfactura}} </td>
                  <td> {{raport.valfactura}} </td>
                  <td> {{raport.valtva}} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="rapoarteFacturiEmiseTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ rapoarteFacturiEmiseTableService.startIndex }} la
                {{ rapoarteFacturiEmiseTableService.endIndex }} din
                {{ rapoarteFacturiEmiseTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="rapoarteFacturiEmiseLength"
                  [(page)]="rapoarteFacturiEmiseTableService.page"
                  [pageSize]="rapoarteFacturiEmiseTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>
