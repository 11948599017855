import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { MovesTableService } from 'src/app/modules/acbcr/common/services/moves-table.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { convertDateToDisplayFormat, convertDateToBackendFormat } from 'src/app/modules/acbcr/common/services/services-table-helper';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-add-edit-miscare-form',
  templateUrl: './add-edit-miscare-form.component.html',
  styleUrls: ['./add-edit-miscare-form.component.scss']
})
export class AddEditMiscareFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() anexa = undefined;
  @Input() anexaId = undefined;
  @Input() moveModalType: string;
  @Input() showAddButton;

  @Output() callbackResult = new EventEmitter();
  @Output() closeForm = new EventEmitter();

  readonly addMove = 'add-miscare';
  readonly editMove = 'edit-miscare';

  submitted = false;
  dateError = false;
  matricoleAnimal = [];
  exploatatiiSursa = [];
  exploatatiiDestinatie = [];
  motiveMiscare = [];

  exploatatieAnimal;
  animalDataNastere;
  moveForm;
  isSuperAdmin = false;
  isFermier = false;
  isAdmin = false;
  isOperator = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  openConfirmModal = false;
  isExploatatie = false;
  showHint = true;
  arataDocument = false;
  fileName;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private helperDataService: HelperDataService,
    private movesTableService: MovesTableService,
    private store: Store<ApplicationState>,
    private animalsTableService: AnimalsTableService,
    private errorService: NotificationErrorService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  ngOnChanges(changes) {
    if (this.moveModalType === this.addMove) {
      this.buildForm();
    } else {
      this.buildForm(this.anexa);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  buildForm(formData?) {
    if (formData) {
      if (formData.exploatatie_sursa_id) {
        this.exploatatiiSursa = [{
          id: formData.exploatatie_sursa_id,
          code: formData.exploatatie_sursa_code,
          name: formData.exploatatie_sursa_name,
          viewLabel: `${formData.exploatatie_sursa_code} - ${formData.exploatatie_sursa_name}`
        }];
      }

      if (formData.exploatatie_destinatie_id) {
        this.exploatatiiDestinatie = [{
          id: formData.exploatatie_destinatie_id,
          code: formData.exploatatie_destinatie_code,
          name: formData.exploatatie_destinatie_name,
          status: formData.exploatatie_destinatie_status,
          viewLabel: `${formData.exploatatie_destinatie_code} - ${formData.exploatatie_destinatie_name}`
        }];
      }
    }

    this.moveForm = new UntypedFormGroup({
      anexa10id: new UntypedFormControl(formData?.anexa10id),
      miscareid: new UntypedFormControl(formData?.miscareid),
      numarmatricol: new UntypedFormControl(formData?.numarmatricol, Validators.required),
      data: new UntypedFormControl(formData?.data, Validators.required),
      intrat: new UntypedFormControl(
        formData && formData.intrat ? formData.intrat.toString() : '2'
      ),
      motiv: new UntypedFormControl(formData?.motiv, Validators.required),
      exploatatie_sursa_id : new UntypedFormControl(
        formData?.exploatatie_sursa_id,
        Validators.required),
      exploatatie_destinatie_id : new UntypedFormControl(
        formData?.exploatatie_destinatie_id),
      obs: new UntypedFormControl(formData?.obs),
      file_id: new UntypedFormControl(formData?.file_id),
      status: new UntypedFormControl(
        formData ? formData?.status?.toString() : '0',
        Validators.required),
    });

    if (formData && !formData.exploatatie_sursa_id) {
      this.moveForm.controls.exploatatie_sursa_id.clearValidators();
      this.moveForm.controls.exploatatie_sursa_id.updateValueAndValidity();
    }

    this.submitted = false;
    this.itemsMotiv();
    this.exploatatiaCurenta();
    this.verificaArataDocument();
    this.fileName = formData?.file_name;

    if (this.fileName) {
      this.arataDocument = true;
    }
  }

  exploatatiaCurenta() {
    if (this.moveForm.value.intrat == 2 && !this.moveForm.value.exploatatie_sursa_id) {
      this.store.pipe(select(getSelectedHolding))
        .subscribe((response: any) => {
          if (response) {
            this.moveForm.controls.exploatatie_sursa_id.setValue(response.id)
            this.exploatatiiSursa = [{
              id: response.id,
              code: response.code,
              name: response.name,
              viewLabel: `${response.code} - ${response.name}`
            }];
          }
        });

    } else if (this.moveForm.value.intrat == 1 && !this.moveForm.value.exploatatie_destinatie_id) {
      this.store.pipe(select(getSelectedHolding))
        .subscribe((response: any) => {
          if (response) {
            this.moveForm.controls.exploatatie_destinatie_id.setValue(response.id)
            this.exploatatiiDestinatie = [{
              id: response.id,
              code: response.code,
              name: response.name,
              viewLabel: `${response.code} - ${response.name}`
            }];
          }
        });
    }
  }

  closeFormMethod() {
    this.closeForm.emit(false);
  }

  openConfirmActionModal() {
    this.openConfirmModal = true;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteMiscare();
    }
  }

  deleteMiscare() {
    if (this.anexa) {
      this.movesTableService.deleteMove(this.anexa.id)
        .subscribe({
          next: (response) => {
            this.callbackResult.emit({anexa10id: this.anexaId});
            this.closeForm.emit(false);
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        });
    }
  }

  onSubmitted() {
    this.submitted = true;
    this.f.anexa10id.setValue(this.anexaId);

    if (!this.moveForm.valid || this.dateError) {
      return;
    }

    if ([1, 5, 6, 7].includes(this.moveForm.value.motiv)) {
      this.moveForm.controls.exploatatie_destinatie_id.setValue(null);
    }

    this.movesTableService.updateAnexa10(this.moveForm.value)
      .subscribe({
        next: (response) => {
          this.callbackResult.emit({anexa10id: response.anexa10id});
          this.closeForm.emit(false);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  // Get forms controls state
  get f() { return this.moveForm.controls; }

  onDataChanged(event) {
    this.f.data.setValue(event);
    this.compareDates(event, this.animalDataNastere);
  }

  compareDates(selectedDate, birthDate) {
    if (Date.parse(convertDateToBackendFormat(selectedDate)) < Date.parse(convertDateToBackendFormat(birthDate))) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  onChangeAnimal(event) {
    this.exploatatiiSursa = [];
    this.moveForm.controls.exploatatie_sursa_id.setValue(null);

    if (event && event['exploatatie_id']) {
      this.exploatatiiSursa = [{
        id: event['exploatatie_id'],
        code: event['cod_exploatatie'],
        name: event['nume_exploatatie'],
        viewLabel: `${event['cod_exploatatie']} - ${event['nume_exploatatie']}`
      }];
      this.moveForm.controls.exploatatie_sursa_id.setValue(event['exploatatie_id'])
    } else {
      this.moveForm.controls.exploatatie_sursa_id.clearValidators();
      this.moveForm.controls.exploatatie_sursa_id.updateValueAndValidity();
    }
  }

  onChangeIntrat() {
    this.f.numarmatricol.reset();
    this.f.motiv.reset();
    this.f.exploatatie_sursa_id.reset();
    this.f.exploatatie_destinatie_id.reset();

    this.matricoleAnimal = [];
    this.exploatatiiSursa = [];
    this.exploatatiiDestinatie = [];

    this.itemsMotiv();
    this.exploatatiaCurenta();
    this.verificaArataDocument();
  }

  searchMatricoleAnimal(event) {
    if (event.term.length < 4) {
      this.matricoleAnimal = [];
      return;
    }

    if (!this.matricoleAnimal || !this.matricoleAnimal.length) {
      let exploatatie_id = this.moveForm.value.exploatatie_sursa_id;

      this.helperDataService.getCompanyAnimalInfo(event.term, exploatatie_id)
      .subscribe((response: any) =>
        this.matricoleAnimal = response.result
      );
    }
  }

  searchExploatatii(event, tip_exploatatii) {
    if (event.term.length < 5) {
      this.showHint = true;

      if (tip_exploatatii == 'sursa') {
        this.exploatatiiSursa = [];
      } else if (tip_exploatatii == 'destinatie') {
        this.exploatatiiDestinatie = [];
      }

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          if (tip_exploatatii == 'sursa') {
            this.exploatatiiSursa = response;
            let destinatie_id = this.moveForm.value.exploatatie_destinatie_id;

            if (this.exploatatiiSursa && destinatie_id) {
              this.exploatatiiSursa = this.exploatatiiSursa.filter(
                (item) => item['id'] != destinatie_id
              );
            }
          } else if (tip_exploatatii == 'destinatie') {
            this.exploatatiiDestinatie = response;
            let sursa_id = this.moveForm.value.exploatatie_sursa_id;

            if (this.exploatatiiDestinatie && sursa_id) {
              this.exploatatiiDestinatie = this.exploatatiiDestinatie.filter(
                (item) => item['id'] != sursa_id
              )
            }
          }
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  selectExploatatie() {
    this.verificaArataDocument();
  }

  verificaArataDocument() {
    let destinatie_id = this.moveForm.value.exploatatie_destinatie_id;
    let exploatatie = this.exploatatiiDestinatie.filter(
      (item) => item['id'] === destinatie_id
    )[0]

    if (!exploatatie) {
      this.arataDocument = false;
      this.moveForm.controls.file_id.clearValidators();
      this.moveForm.controls.file_id.updateValueAndValidity();
      return;
    }

    if (exploatatie['status'] === 1
      && this.moveForm.value.intrat === '2'
      && [2, 4].includes(this.moveForm.value.motiv)
    ) {
      this.arataDocument = true;
      this.moveForm.controls.file_id.setValidators(Validators.required);
    } else {
      this.arataDocument = false;
      this.moveForm.controls.file_id.clearValidators();
    }

    this.moveForm.controls.file_id.updateValueAndValidity();
  }

  itemsMotiv() {
    if (this.moveForm.value.intrat == 2) {
      this.motiveMiscare = [
        {motiv: 'Abatorizare', value: 1},
        {motiv: 'Vânzare', value: 2},
        {motiv: 'Transfer', value: 4},
        {motiv: 'Pierdere', value: 5},
        {motiv: 'Moarte', value: 6},
        {motiv: 'Vânzare exploatație neafiliată', value: 7},
        {motiv: 'Sacrificare consum propriu', value: 8},
      ];
    } else if (this.moveForm.value.intrat == 1) {
      this.motiveMiscare = [
        {motiv: 'Cumpărare', value: 3},
        {motiv: 'Transfer', value: 4}
      ];
    }
  }

  onChangeMotiv() {
    if ([2, 3, 4].includes(this.moveForm.value.motiv)) {
      this.moveForm.controls.exploatatie_destinatie_id.setValidators(Validators.required);
    } else {
      this.moveForm.controls.exploatatie_destinatie_id.clearValidators();
    }

    this.moveForm.controls.exploatatie_destinatie_id.updateValueAndValidity();
    this.verificaArataDocument();
  }

  uploadServerResponse(event) {
    if (!event.body || !event.body.files) {
      return;
    }

    const file = event.body.files[0];
    this.moveForm.controls.file_id.setValue(file.file_id);
    this.fileName = file.file_uploaded_name;
  }

  downloadFile(event) {
    event.target.disabled = true;
    let fileid = this.moveForm.controls.file_id.value;

    this.helperDataService.downloadFile(fileid)
      .subscribe({
        next: (response: any) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, this.fileName);
          event.target.disabled = false;

        },
        error: (errors) => {
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }

  deleteFile() {
    this.moveForm.controls.file_id.setValue(null);
    this.fileName = null;
  }
}
