<div class="card" id="performanta" [formGroup]="animalForm">
  <h3 class="card-header">Performanță</h3>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
          <!-- sales analytics chart -->
          <div style="height: 180px;">
            <apx-chart
              [series]="salesMixedChart.series"
              [chart]="salesMixedChart.chart"
              [tooltip]="salesMixedChart.tooltip"
              [stroke]="salesMixedChart.stroke"
              [fill]="salesMixedChart.fill"
              [legend]="salesMixedChart.legend"
              [plotOptions]="salesMixedChart.plotOptions"
              [grid]="salesMixedChart.grid"
              [xaxis]="salesMixedChart.xaxis"
              [yaxis]="salesMixedChart.yaxis"
              [colors]="salesMixedChart.colors"
              [labels]="salesMixedChart.labels"
            >
            </apx-chart>
          </div>
      </div>

      <div class="col-sm-6 col-lg-3 align-self-center">
        <div class="form-group mb-3">
          <label for="greutate_nastere">
            Greutate la naștere
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                placeholder="Inserați greutatea în KG"
                class="form-control"
                formControlName="greutate_nastere"
                (keyup)="greutateNastereChanged()"
                (input)="$event.target.value = $event.target.value.toUpperCase()"
                [ngClass]="{
                  'is-invalid': submitted && form.greutate_nastere.errors
                }"
                [readonly]="disabled.greutate_nastere || disableAllFields"
              />
              <span class="input-group-text">kg</span>
            </div>
            <div
              *ngIf="submitted && form.greutate_nastere.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && form.greutate_nastere.errors }"
            >
              <div *ngIf="form.greutate_nastere.errors.required">
                Completarea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="gvr120" class="top_label">
            Greutate la 120 zile
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                placeholder="Inserați greutatea în KG"
                class="form-control"
                formControlName="gvr120"
                (keyup)="greutate120Changed()"
                (input)="$event.target.value = $event.target.value.toUpperCase()"
                [ngClass]="{
                  'is-invalid': submitted && form.gvr120.errors
                }"
                [readonly]="disabled.gvr120 || disableAllFields"
                />
              <span class="input-group-text">kg</span>
            </div>
          </div>
          <div
            *ngIf="submitted && form.gvr120.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && form.gvr120.errors }"
          >
            <div *ngIf="form.gvr120.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="smz120">
            SMZ la 120 zile
          </label>

          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                min="0"
                id="smz120"
                placeholder="Inserați SMZ"
                class="form-control"
                formControlName="smz120"
                [ngClass]="{
                  'is-invalid': submitted && form.smz120.errors
                }"
                [readonly]="disabled.smz120 || disableAllFields"
              />
              <span class="input-group-text">g</span>
            </div>
          </div>

          <div
            *ngIf="submitted && form.smz120.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && form.smz120.errors }"
          >
            <div *ngIf="form.smz120.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="gvr200" class="top_label">
            Greutate la 200 zile
          </label>
          <span
            *ngIf="tooltipCantarire200"
            [ngbTooltip]="tooltipCantarire200"
            class="mdi mdi-weight-kilogram kg-icon"
          ></span>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                placeholder="Inserați greutatea în KG"
                class="form-control"
                formControlName="gvr200"
                (keyup)="greutate200Changed()"
                (input)="$event.target.value = $event.target.value.toUpperCase()"
                [ngClass]="{
                  'is-invalid': submitted && form.gvr200.errors
                }"
                [readonly]="disabled.gvr200 || disableAllFields"
              />
              <span class="input-group-text">kg</span>
            </div>
          </div>
          <div
            *ngIf="submitted && form.gvr200.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && form.gvr200.errors }"
          >
            <div *ngIf="form.gvr200.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="smz200">
            SMZ la 200 zile
          </label>

          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                min="0"
                id="smz200"
                placeholder="Inserați SMZ"
                class="form-control"
                formControlName="smz200"
                [ngClass]="{
                  'is-invalid': submitted && form.smz200.errors
                }"
                [readonly]="disabled.smz200 || disableAllFields"
              />
              <span class="input-group-text">g</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="gvr365" class="top_label">
            Greutate la 365 zile
          </label>
          <span
            *ngIf="tooltipCantarire365"
            [ngbTooltip]="tooltipCantarire365"
            class="mdi mdi-weight-kilogram kg-icon"
          ></span>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                placeholder="Inserați greutatea în KG"
                class="form-control"
                formControlName="gvr365"
                (keyup)="greutate365Changed()"
                (input)="$event.target.value = $event.target.value.toUpperCase()"
                [ngClass]="{
                  'is-invalid': submitted && form.gvr365.errors
                }"
                [readonly]="disabled.gvr365 || disableAllFields"
                />
              <span class="input-group-text">kg</span>
            </div>
          </div>
          <div
            *ngIf="submitted && form.gvr365.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && form.gvr365.errors }"
          >
            <div *ngIf="form.gvr365.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="smz365">
            SMZ la 365 zile
          </label>

          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                min="0"
                id="smz365"
                placeholder="Inserați SMZ"
                class="form-control"
                formControlName="smz365"
                [ngClass]="{
                  'is-invalid': submitted && form.smz365.errors
                }"
                [readonly]="disabled.smz365 || disableAllFields"
              />
              <span class="input-group-text">g</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="dm">
            Valoare DM
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                id="dm"
                placeholder="Valoare DM"
                formControlName="dm"
                type="number"
                min="0"
                step="1"
                class="form-control read-only-form"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
              />
              <button
                class="btn btn-sm btn-icon btn-success"
                title="Editează"
                type="button"
                (click)="openModalBonitare('dm')"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
              >
                <i class="mdi mdi-square-edit-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="ds">
            Valoare DS
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                min="0"
                step="1"
                id="ds"
                placeholder="Valoarea DS"
                class="form-control read-only-form"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
                formControlName="ds"
              />
              <button
                class="btn btn-sm btn-icon btn-success"
                title="Editează"
                type="button"
                (click)="openModalBonitare('ds')"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
              >
                <i class="mdi mdi-square-edit-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="af">
            Valoare AF
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <input
                type="number"
                min="0"
                step="1"
                id="af"
                placeholder="Valoarea AF"
                class="form-control read-only-form"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
                formControlName="af"
              />
              <button
                class="btn btn-sm btn-icon btn-success"
                title="Editează"
                type="button"
                (click)="openModalBonitare('af')"
                [ngClass]="{'disabled' : isFermier || disableAllFields}"
              >
                <i class="mdi mdi-square-edit-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-3">
          <label for="cr">
            Valoare CR
          </label>
          <input
            type="number"
            min="0"
            step="1"
            id="cr"
            placeholder="Valoarea CR"
            class="form-control"
            formControlName="cr"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="ifnais">
            IFNAIS
          </label>
          <input
            type="number"
            min="0"
            id="ifnais"
            placeholder="IFNAIS"
            class="form-control"
            formControlName="ifnais"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="crsevr">
            CRSEVR
          </label>
          <input
            type="number"
            min="0"
            id="crsevr"
            placeholder="CRSEVR"
            class="form-control"
            formControlName="crsevr"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="dmsev">
            DMSEV
          </label>
          <input
            type="number"
            min="0"
            id="dmsev"
            placeholder="DMSEV"
            class="form-control"
            formControlName="dmsev"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="dssev">
            DSSEV
          </label>
          <input
            type="number"
            min="0"
            id="dssev"
            placeholder="DSSEV"
            class="form-control"
            formControlName="dssev"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="isevr">
            ISEVR
          </label>
          <input
            type="number"
            min="0"
            id="isevr"
            placeholder="ISEVR"
            class="form-control"
            formControlName="isevr"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="avel">
            AVEL
          </label>
          <input
            type="number"
            min="0"
            id="avel"
            placeholder="AVEL"
            class="form-control"
            formControlName="avel"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="alait">
            ALAIT
          </label>
          <input
            type="number"
            min="0"
            id="alait"
            placeholder="ALAIT"
            class="form-control"
            formControlName="alait"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>

      <div class="col-sm-3 lg_8_cols">
        <div class="form-group mb-3">
          <label for="ivmat">
            IVMAT
          </label>
          <input
            type="number"
            min="0"
            id="ivmat"
            placeholder="IVMAT"
            class="form-control"
            formControlName="ivmat"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          />
        </div>
      </div>
    </div>

    <div class="separator mb-3"></div>

    <div class="col-sm-12"><h4>EVALUARE GENETICA</h4></div>

    <div class="row pb-3 pt-1">
      <div *ngIf="evaluareGenetica" class="ev_gen_container">
        <div
          *ngFor="let key of ev_gen_cols"
          [ngClass]="['ev_gen_cell', key]"
        >
          <div class="ev_gen_header" [ngbTooltip]="columnsDesc[key]['desc']" >
            {{ columnsDesc[key]['abr'] }}
          </div>

          <div
            [ngClass]="{
              'ev_gen_content': true,
              'number': number.indexOf(key) != -1,
              'percent': percent.indexOf(key) != -1
            }"
          >{{ evaluareGenetica[key] }}</div>
        </div>
      </div>
    </div>

    <div class="separator mb-3"></div>

    <div class="col-sm-12"><h4>Fișă bonitare</h4></div>

    <div class="row pb-3 pt-1">
      <div *ngIf="!isFermier && !disableAllFields" class="col-sm-12 col-md-6">
          <app-upload-fisier
            (ApiResponse)="uploadFisaBonServerResponse($event)"
          >
          </app-upload-fisier>
      </div>

      <div *ngIf="fileIdFisaBonitare" class="col-sm-12 col-md-6">
        <button
          type="button"
          class="btn p-0 text-blue font-weight-bold"
          (click)="downloadFisaBonitare()"
        >
          <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
          {{ fileNameFisaBonitare }}
        </button>

        <button
          *ngIf="!isFermier && !disableAllFields"
          type="button"
          class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
          ngbTooltip="Șterge fișier"
          placement="right"
          (click)="deleteFisaBonitare()"
        >
        </button>
      </div>
    </div>

    <div class="separator mb-3"></div>

    <div class="col-sm-12"><h4>Buletin apreciere fenotipică</h4></div>

    <div class="row pb-3 pt-1">
      <div *ngIf="!isFermier && !disableAllFields" class="col-sm-12 col-md-6">
        <app-upload-fisier
          (ApiResponse)="uploadServerResponse($event)"
        >
        </app-upload-fisier>
      </div>

      <div *ngIf="fileId" class="col-sm-12 col-md-6">
        <button
          type="button"
          class="btn p-0 text-blue font-weight-bold"
          (click)="downloadDocument()"
        >
          <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
          {{ fileName }}
        </button>

        <button
          *ngIf="!isFermier && !disableAllFields"
          type="button"
          class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
          ngbTooltip="Șterge fișier"
          placement="right"
          (click)="deleteDocument()"
        >
        </button>
      </div>
    </div>

    <div class="row pl-3" *ngIf="fileId">
      <div class="form-group mb-3">
        <label for="docnr_apr_feno">
          Document Nr.*
        </label>
        <input
          type="text"
          class="form-control document_nr mr-4"
          placeholder="Introduceți numărul documentului"
          formControlName="docnr_apr_feno"
          [ngClass]="{
            'is-invalid': submitted && form.docnr_apr_feno.errors
          }"
          [readonly]="isFermier || disableAllFields"
        />
        <div
          *ngIf="submitted && form.docnr_apr_feno.errors"
          class="invalid-feedback"
        >
          <div *ngIf="form.docnr_apr_feno.errors.required">
            Completarea acestui câmp este obligatorie.
          </div>
        </div>
      </div>

      <div class="form-group mb-3">
        <label for="data_apr_feno">
          Document Data*
        </label>
        <!-- formControlName = data_apr_feno -->
        <app-date-picker
          [submitted]="submitted"
          [required]="fileId ? true : false"
          [selectedDate]="form.data_apr_feno.value"
          [disabled]="isFermier || disableAllFields"
          (dateChanged)="documentDataChanged($event)"
        >
        </app-date-picker>
      </div>

    </div>
  </div>
</div>

<app-add-update-fisa-bonitare
  [openBonitare]="openBonitare"
  [bonitareType]="bonitareType"
  (notifyBonClose)="closeBonitareModal($event)"
  (dmValue)="dmValue($event)"
  (dsValue)="dsValue($event)"
  (afValue)="afValue($event)"
></app-add-update-fisa-bonitare>
