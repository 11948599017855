import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { ChartPerformanceAnimalType } from 'src/app/modules/acbcr/common/models/animal.models';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import {
  evaluareGeneticaNumber,
  evaluareGeneticaPercent,
  evaluareGeneticaColumnsDesc
} from 'src/app/modules/acbcr/components/pages/evaluare-genetica/evaluare-genetica.component'
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-performanta-form-block',
  templateUrl: './performanta-form-block.component.html',
  styleUrls: ['./performanta-form-block.component.scss']
})
export class PerformantaFormBlockComponent implements OnInit, OnChanges {
  @Input() animalForm: UntypedFormGroup;
  @Input() evaluareGenetica;
  @Input() areFatare;
  @Input() modalType: string;
  @Input() submitted: boolean;
  @Input() disableAllFields: boolean;
  @Output() openBonitareModal = new EventEmitter();

  salesMixedChart: ChartPerformanceAnimalType;
  fileId = null;
  fileName = '';
  fileIdFisaBonitare = null;
  fileNameFisaBonitare = '';
  resetUpload = false;
  resetBonitareUpload = false;
  bonitareType: string;
  openBonitare = false;

  disabled = {
    greutate_nastere: false,
    gvr120: false,
    gvr200: false,
    gvr365: false,
    smz120: false,
    smz200: false,
    smz365: false,
  }
  tooltipCantarire200;
  tooltipCantarire365;

  uploadConfig = UIHelper.uploadConfig;

  ev_gen_cols = [
    'consangvinizare',
    'va_absolut_greutate_nastere',
    'va_relativ_greutate_nastere',
    'acc_greutate_nastere',
    'va_absolut_greutate_intarcare',
    'va_relativ_greutate_intarcare',
    'acc_greutate_intarcare',
    'va_absolut_greutate_smz',
    'va_relativ_greutate_smz',
    'acc_smz',
    'ebv_carne_absolut',
    'ebv_carne_relativ',
    'acc_carne',
    'va_absolut_greutate_nota_fatare',
    'va_relativ_greutate_nota_fatare',
    'acc_nota_fatare',
    'ebv_reproductie_absolut',
    'ebv_reproductie_relativ',
    'acc_reproductie',
    'ebv_total_absolut',
    'ebv_total_relativ',
    'acc_total',
    'data'
  ];
  number = evaluareGeneticaNumber;
  percent = evaluareGeneticaPercent;
  columnsDesc = evaluareGeneticaColumnsDesc;
  isSuperAdmin = false;
  isFermier = false;

  constructor(
    private helperDataService: HelperDataService,
    private animalTableService: AnimalsTableService
    ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isFermier = UIHelper.isFermier();

    this._fetchData();
  }

  ngOnChanges() {
    if (this.animalForm.value.id) {
      this.animalTableService
        .getAnimalPerformance(this.animalForm.value.id)
        .subscribe(response => {
          this.form.greutate_nastere.setValue(response.greutate_nastere);
          this.form.gvr120.setValue(response.gvr120);
          this.form.gvr200.setValue(response.gvr200);
          this.form.gvr365.setValue(response.gvr365);
          this.form.gvr200_source.setValue(response.gvr200_source);
          this.form.gvr365_source.setValue(response.gvr365_source);
          this.form.smz120.setValue(response.smz120);
          this.form.smz200.setValue(response.smz200);
          this.form.smz365.setValue(response.smz365);

          this.checkDisabled(response);
          this.creazaTooltipCantarire(response);
          this._fetchData();
        });

      this.fileId = this.form.file_id_apr_feno.value;
      this.fileName = this.form.file_name_apr_feno.value;
      this.fileIdFisaBonitare = this.form.fileidfisabonitare.value;
      this.fileNameFisaBonitare = this.form.filename_fisabonitare.value;
      this.checkDocumentValidators();
    }
  }

  checkDisabled(response) {
    if (!this.isSuperAdmin && this.evaluareGenetica) {
      let disable_keys = ['greutate_nastere', 'gvr200', 'gvr365', 'smz200', 'smz365'];

      for (let key of disable_keys) {
        if (response[key] > 0) {
          this.disabled[key] = true;
        }
      }
    }

    if (this.isFermier) {
      this.disabled = {
        greutate_nastere: true,
        gvr120: true,
        gvr200: true,
        gvr365: true,
        smz120: true,
        smz200: true,
        smz365: true,
      }
    }

    if (!this.isSuperAdmin && this.areFatare && response.greutate_nastere > 0) {
      this.disabled.greutate_nastere = true;
    }

    if (response.gvr200_source == 1) {
      this.disabled.gvr200 = true;
      this.disabled.smz200 = true;
    }

    if (response.gvr365_source == 1) {
      this.disabled.gvr365 = true;
      this.disabled.smz365 = true;
    }
  }

  creazaTooltipCantarire(response) {
    let g200 = response.greutate_cantarire200;
    let date200 = response.data_cantarire200;
    let g365 = response.greutate_cantarire365;
    let date365 = response.data_cantarire365;

    if (g200 && response.gvr200_source == 1) {
      this.tooltipCantarire200 = `Calculată folosind cântărirea de ${g200} kg din ${date200}`;
    }

    if (g365 && response.gvr365_source == 1) {
      this.tooltipCantarire365 = `Calculată folosind cântărirea de ${g365} kg din ${date365}`
    }
  }

  get form() {
    return this.animalForm.controls;
  }

  uploadServerResponse(event) {
    const file = event.body.files[0];
    if (this.fileId) {
      this.helperDataService.deleteFile(this.fileId).subscribe();
    }

    this.fileId = file.file_id;
    this.fileName = file.file_uploaded_name;
    this.form.file_id_apr_feno.setValue(this.fileId);
    this.resetDocumentControls();
    this.checkDocumentValidators();
  }

  uploadFisaBonServerResponse(event) {
    const file = event.body.files[0];

    if (this.fileIdFisaBonitare) {
      this.helperDataService.deleteFile(this.fileIdFisaBonitare).subscribe();
    }

    this.fileIdFisaBonitare = file.file_id;
    this.fileNameFisaBonitare = file.file_uploaded_name;
    this.form.fileidfisabonitare.setValue(this.fileIdFisaBonitare);
  }

  downloadFisaBonitare() {
    if (this.fileIdFisaBonitare) {
      this.helperDataService.downloadFile(this.fileIdFisaBonitare)
        .subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          this.helperDataService.simulateDownload(blob, this.fileNameFisaBonitare);
      });
    }
  }

  downloadDocument() {
    if (this.fileId) {
      this.helperDataService.downloadFile(this.fileId)
        .subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          this.helperDataService.simulateDownload(blob, this.fileName);
      });
    }
  }

  deleteDocument() {
    if (this.fileId) {
      this.helperDataService.deleteFile(this.fileId)
        .subscribe((response: any) => {
          if (response.status === 200) {
            if (this.animalForm.value.id) {
              this.animalTableService.deleteAprFenotipicaFile(this.animalForm.value.id)
                .subscribe();
            }
            this.resetUploadFile(this.resetUpload);

            this.fileId = null;
            this.fileName = null;
            this.form.file_id_apr_feno.setValue(null);
            this.form.file_name_apr_feno.setValue('');
            this.resetDocumentControls();
            this.checkDocumentValidators();
          }
      });
    }
  }

  deleteFisaBonitare() {
    if (this.fileIdFisaBonitare) {
      this.helperDataService.deleteFile(this.fileIdFisaBonitare)
        .subscribe((response: any) => {
          if (response.status === 200) {
            if (this.animalForm.value.id) {
              this.animalTableService.deleteFisaBonitareFile(this.animalForm.value.id)
                .subscribe();
            }
            this.resetUploadFile(this.resetBonitareUpload);

            this.fileIdFisaBonitare = null;
            this.fileName = null;
            this.form.file_id_apr_feno.setValue(null);
            this.form.file_name_apr_feno.setValue('');
            this.resetDocumentControls();
            this.checkDocumentValidators();
          }
      });
    }
  }

  documentDataChanged(event) {
    this.form.data_apr_feno.setValue(event);
  }

  greutateNastereChanged() {
    this.salesMixedChart.series[0].data[0] = Number(this.animalForm.value.greutate_nastere);
    this.salesMixedChart = JSON.parse(JSON.stringify(this.salesMixedChart));
  }

  greutate120Changed() {
    this.salesMixedChart.series[0].data[1] = Number(this.animalForm.value.gvr120);
    this.salesMixedChart = JSON.parse(JSON.stringify(this.salesMixedChart));
  }

  greutate200Changed() {
    this.salesMixedChart.series[0].data[2] = Number(this.animalForm.value.gvr200);
    this.salesMixedChart = JSON.parse(JSON.stringify(this.salesMixedChart));
  }

  greutate365Changed() {
    this.salesMixedChart.series[0].data[3] = Number(this.animalForm.value.gvr365);
    this.salesMixedChart = JSON.parse(JSON.stringify(this.salesMixedChart));
  }

  private resetUploadFile(reset) {
    reset = true;
    setTimeout(() =>
      reset = false, 0);
  }

  private checkDocumentValidators() {
    if (this.fileId) {
      this.form.data_apr_feno.setValidators(Validators.required);
      this.form.docnr_apr_feno.setValidators(Validators.required);
    } else {
      this.form.data_apr_feno.clearValidators();
      this.form.docnr_apr_feno.clearValidators();
    }

    this.form.data_apr_feno.updateValueAndValidity();
    this.form.docnr_apr_feno.updateValueAndValidity();
  }

  private resetDocumentControls() {
    this.form.data_apr_feno.reset();
    this.form.docnr_apr_feno.reset();
  }

  private _fetchData() {
    const salesMixedChart: ChartPerformanceAnimalType = {
      chart: {
        height: 180,
        type: 'line',
        padding: {
          right: 0,
          left: 0,
          top: 0,
          bottom: 0
        },
        stacked: false,
        toolbar: {
          show: false
        },
        parentHeightOffset: 0,
      },
      stroke: {
        width: [0, 2, 4],
        curve: 'straight'
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
      colors: ['#1abc9c', '#e3eaef', '#4a81d4'],
      series: [
        {
          name: 'Greutate: ',
          type: 'column',
          data: [
            this.animalForm.value.greutate_nastere,
            this.animalForm.value.gvr120,
            this.animalForm.value.gvr200,
            this.animalForm.value.gvr365,
          ]
        },
      ],
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels: [
        'Naștere',
        '120 zile',
        '200 zile',
        '365 zile',
      ],
      markers: {
        size: 0
      },
      legend: {
        show: false
      },
      xaxis: {
        // type: 'datetime',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter(y) {
            if (y != null) {
              return y.toFixed(0) + ' KG';
            }
            return y;
          }
        }
      },
      grid: {
        borderColor: '#f1f3fa'
      }
    };
    this.salesMixedChart = salesMixedChart;
  }

  openModalBonitare(type) {
    this.bonitareType = type;
    this.openBonitare = true;
    this.openBonitareModal.emit(true);
  }

  closeBonitareModal(event) {
    this.openBonitare = event;
    this.openBonitareModal.emit(false);
  }

  dmValue(event) {
    this.animalForm.controls.dm.setValue(event);
  }

  dsValue(event) {
    this.animalForm.controls.ds.setValue(event);
  }

  afValue(event) {
    this.animalForm.controls.af.setValue(event);
  }
}
