<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="buletinTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group"
              >

                <button
                  class="btn btn-danger btn-sm"
                  type="button"
                  (click)="openAddBuletinModal('atentionari')"
                >
                  <i class="fe-alert-triangle"></i>
                  Atenționări pentru fermier
                </button>

                <button
                  *ngIf="!isAutoritateJudeteana && !isFermier && !isContabil"
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openAddBuletinModal('add')"
                >
                  <i class="fe-save"></i>
                  Adaugă buletin
                </button>
              </div>
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive" #buletineTable>
            <table
              id="buletine-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="type" (sort)="onSort($event)">
                    Buletin de control
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    Dată cântărire
                  </th>
                  <th sortable="date" (sort)="onSort($event)">
                    Date înregistrare
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="buletinTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit nici un buletin de control.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let cantarire of cantariri">
                  <td>
                    <span>Greutate la vârsta de {{ cantarire.type }} luni</span>
                  </td>
                  <td> {{cantarire.date}} </td>
                  <td>
                    <span>
                      {{cantarire.nr_total_cantariri - cantarire.nr_cantariri_nule}}
                      / {{ cantarire.nr_total_cantariri }} cântăriri înscrise
                    </span>
                  </td>

                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        *ngIf="!isFermier"
                        class="btn btn-sm btn-icon btn-success"
                        [title]="isAutoritateJudeteana || isContabil ? 'Vizualizează' : 'Editează'"
                        (click)="editBuletinControl(cantarire.companyid, cantarire.id)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        ngbTooltip="Vizualizează PDF buletin"
                        placement="bottom"
                        (click)="viewBuletinControl(cantarire)"
                      >
                        <i class="fe-eye"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-info"
                        ngbTooltip="Descarcă PDF buletin"
                        placement="bottom"
                        (click)="downloadBuletin(cantarire)"
                      >
                        <i class="fe-download"></i>
                      </button>
                      <button
                        *ngIf="!isAutoritateJudeteana && !isContabil"
                        type="button"
                        class="btn btn-sm btn-warning"
                        ngbTooltip="Descarcă XLS buletin"
                        placement="bottom"
                        (click)="exportxls(cantarire.id)"
                      >
                        Export XLS
                      </button>
                      <button
                        *ngIf="isAdmin"
                        class="btn btn-sm btn-icon btn-danger"
                        title="Șterge"
                        (click)="openConfirmActionModal(cantarire.id)"
                      >
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-md-between align-items-md-center mt-2"
            *ngIf="buletinTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-md-5">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ buletinTableService.startIndex }} la
                {{ buletinTableService.endIndex }} din
                {{ buletinTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="cantaririLength"
                  [(page)]="buletinTableService.page"
                  [pageSize]="buletinTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-add-buletin-control
  [open]="openAddBuletinControlModal"
  (notifyClose)="closeAddOperatorModal($event)"
  (callbackResult)="getModalResult($event)"
  [modalType]="modalTypeAddBuletinControl"
>
</app-add-buletin-control>

<app-edit-buletin-control
  [open]="openEditBuletinControlModal"
  [count]="count"
  [cantaririBuletin]="cantaririBuletin"
  [cantaririDetails]="cantaririDetails"
  (notifyClose)="closeEditOperatorModal($event)"
  (callbackResult)="getModalResult($event)"
  [loadingDataSpinner]="loadingDataSpinner"
>
</app-edit-buletin-control>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-buletin'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
