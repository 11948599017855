<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <form [formGroup]="settingsForm">

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Datele asociației</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="asociatie_nume">
                Numele asociației
              </label>
              <input
                type="text"
                id="asociatie_nume"
                formControlName="asociatie_nume"
                placeholder="Introduceți numele asociatiei"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_nume.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.asociatie_nume.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.asociatie_nume.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="asociatie_adresa">
                Adresa asociației
              </label>
              <input
                type="text"
                id="asociatie_adresa"
                formControlName="asociatie_adresa"
                placeholder="Introduceți adresa asociatiei"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_adresa.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.asociatie_adresa.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.asociatie_adresa.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-3">
            <div class="form-group mb-3">
              <label for="asociatie_localitate">
                Localitatea asociației
              </label>
              <input
                type="text"
                id="asociatie_localitate"
                formControlName="asociatie_localitate"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_localitate.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.asociatie_localitate.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.asociatie_localitate.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-3">
            <div class="form-group mb-3">
              <label for="asociatie_cif">
                CIF
              </label>
              <input
                type="text"
                id="asociatie_cif"
                formControlName="asociatie_cif"
                placeholder="Introduceți CIF"
                [ngClass]="{ 'is-invalid': (submitted && f.asociatie_cif.errors) || !invalidCif }"
                (blur)="validateCIF($event)"
                class="form-control"
                (input)="settingsForm.patchValue({ asociatie_cif: $event.target.value.toUpperCase()})"
                [readonly]="!isSuperAdmin"
              />
              <div *ngIf="!invalidCif" class="invalid-feedback">
                <div>
                  CIF-ul introdus nu este valid.
                </div>
              </div>

              <div *ngIf="submitted && f.asociatie_cif.errors" class="invalid-feedback">
                <div *ngIf="f.asociatie_cif.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="asociatie_nr_reg">
                Numărul registrului
              </label>
              <input
                type="text"
                id="asociatie_nr_reg"
                formControlName="asociatie_nr_reg"
                placeholder="Introduceți numarul registrului"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_nr_reg.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.asociatie_nr_reg.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.asociatie_nr_reg.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="separator mb-3"></div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Datele de contact ale asociației</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="asociatie_email">
                Email
              </label>
              <input
                type="text"
                id="asociatie_email"
                formControlName="asociatie_email"
                placeholder="Introduceți adresa de email"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_email.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.asociatie_email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.asociatie_email.errors.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                  <div *ngIf="f.asociatie_email.errors.email">
                    Adresă email invalidă.
                  </div>
                </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="asociatie_tel">
                Telefon
              </label>
              <input
                type="text"
                id="asociatie_tel"
                formControlName="asociatie_tel"
                placeholder="Introduceți numarul de telefon"
                [ngClass]="{ 'is-invalid': submitted && f.asociatie_tel.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.asociatie_tel.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.asociatie_tel.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="separator mb-3"></div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Contabilitate</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3">
              <label for="contributia_clientului">
                Contribuția clientului
              </label>
              <input
                type="text"
                id="contributia_clientului"
                formControlName="contributia_clientului"
                placeholder="Introduceți contributia clientului"
                [ngClass]="{ 'is-invalid': submitted && f.contributia_clientului.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.contributia_clientului.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.contributia_clientului.errors.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div class="separator mb-3"></div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Date gestație</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_intre_fatari">
                Durata între fătări
              </label>
              <input
                type="number"
                min="0"
                id="durata_intre_fatari"
                formControlName="durata_intre_fatari"
                placeholder="Introduceți durata intre fatari"
                [ngClass]="{ 'is-invalid': submitted && f.durata_intre_fatari.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.durata_intre_fatari.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_intre_fatari.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_maxima_fatare">
                Durata maximă fătare
                <span
                  ngbTooltip="Nr. de zile dintre data fătarii și data curentă"
                  class="text-info font-weight-bold h4"
                >&#9432;</span>
              </label>
              <input
                type="number"
                min="0"
                id="durata_maxima_fatare"
                formControlName="durata_maxima_fatare"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_maxima_fatare.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.durata_maxima_fatare.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_maxima_fatare.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_minima_gestatie">
                Durata minimă gestație
              </label>
              <input
                type="number"
                min="0"
                id="durata_minima_gestatie"
                formControlName="durata_minima_gestatie"
                placeholder="Introduceți durata minima gestatie"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_minima_gestatie.errors || showErrorMin) }"
                class="form-control"
                (change)="changeDurataMinimaGestatie($event)"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && showErrorMin"
                class="invalid-feedback"
              >
                <div *ngIf="showErrorMin">
                  Durata minimă gestație nu poate fi mai mare decât perioada maximă gestație.
                </div>
              </div>
              <div
                *ngIf="submitted && f.durata_minima_gestatie.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_minima_gestatie.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_maxima_gestatie">
                Durata maximă gestație
              </label>
              <input
                type="number"
                min="0"
                id="durata_maxima_gestatie"
                formControlName="durata_maxima_gestatie"
                placeholder="Introduceți durata maxima gestatie"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_maxima_gestatie.errors || showError) }"
                class="form-control"
                (change)="changeDurataMaximaGestatie($event)"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && showError"
                class="invalid-feedback"
              >
                <div *ngIf="showError">
                  Durata maximă gestație nu poate fi mai mică decât perioada minimă gestație.
                </div>
              </div>
              <div
                *ngIf="submitted && f.durata_maxima_gestatie.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_maxima_gestatie.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="durata_minima_ia">
                Durata minimă între două I.A.
              </label>
              <input
                type="number"
                min="0"
                id="durata_minima_ia"
                formControlName="durata_minima_ia"
                placeholder="Introduceți durata minimă între două I.A."
                [ngClass]="{ 'is-invalid': submitted && (f.durata_minima_ia.errors || showErrorIAMin) }"
                class="form-control"
                (change)="changeDurataMinimaIA($event)"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && showErrorIAMin"
                class="invalid-feedback"
              >
                <div *ngIf="showErrorIAMin">
                  Durata minimă între două I.A. nu poate fi mai mare decat durata maximă între două I.A.
                </div>
              </div>
              <div
                *ngIf="submitted && f.durata_minima_ia.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_minima_ia.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="durata_maxima_ia">
                Durata maximă între două I.A.
              </label>
              <input
                type="number"
                min="0"
                id="durata_maxima_ia"
                formControlName="durata_maxima_ia"
                placeholder="Introduceți durata maximă între două I.A."
                [ngClass]="{ 'is-invalid': submitted && (f.durata_maxima_ia.errors || showErrorIA) }"
                class="form-control"
                (change)="changeDurataMaximaIA($event)"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && showErrorIA"
                class="invalid-feedback"
              >
                <div *ngIf="showErrorIA">
                  Durata maximă între două I.A. nu poate fi mai mica decat durata minimă între două I.A.
                </div>
              </div>
              <div
                *ngIf="submitted && f.durata_maxima_ia.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_maxima_ia.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="diferenta_varsta_vitel_mama">
                Diferența minimă între vârsta mamei și a vițelului
              </label>
              <input
                type="number"
                min="0"
                id="diferenta_varsta_vitel_mama"
                formControlName="diferenta_varsta_vitel_mama"
                placeholder="Introduceți diferența minimă între vârsta mamei și a vițelului"
                [ngClass]="{ 'is-invalid': submitted && f.diferenta_varsta_vitel_mama.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.diferenta_varsta_vitel_mama.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.diferenta_varsta_vitel_mama.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="diferenta_varsta_vitel_tata">
                Diferența între vârsta tatălui și vârsta vițelului
              </label>
              <input
                type="number"
                min="0"
                id="diferenta_varsta_vitel_tata"
                formControlName="diferenta_varsta_vitel_tata"
                placeholder="Introduceți diferența între vârsta tatălui și vârsta vițelului"
                [ngClass]="{ 'is-invalid': submitted && f.diferenta_varsta_vitel_tata.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.diferenta_varsta_vitel_tata.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.diferenta_varsta_vitel_tata.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_minima_monta">
                Vârsta minimă pentru montă
              </label>
              <input
                type="number"
                min="0"
                id="varsta_minima_monta"
                formControlName="varsta_minima_monta"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_minima_monta.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_minima_monta.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_minima_monta.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_maxima_junica">
                Vârsta maximă categorie junică, femelă
              </label>
              <input
                type="number"
                min="0"
                id="varsta_maxima_junica"
                formControlName="varsta_maxima_junica"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_maxima_junica.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_maxima_junica.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_maxima_junica.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_minima_cantarire_200">
                Vârsta minimă Cântărire 200
              </label>
              <input
                type="number"
                min="0"
                id="varsta_minima_cantarire_200"
                formControlName="varsta_minima_cantarire_200"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_minima_cantarire_200.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_minima_cantarire_200.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_minima_cantarire_200.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_maxima_cantarire_200">
                Vârsta maximă Cântărire 200
              </label>
              <input
                type="number"
                min="0"
                id="varsta_maxima_cantarire_200"
                formControlName="varsta_maxima_cantarire_200"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_maxima_cantarire_200.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_maxima_cantarire_200.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_maxima_cantarire_200.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_minima_cantarire_365">
                Vârsta minimă Cântărire 365
              </label>
              <input
                type="number"
                min="0"
                id="varsta_minima_cantarire_365"
                formControlName="varsta_minima_cantarire_365"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_minima_cantarire_365.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_minima_cantarire_365.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_minima_cantarire_365.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>


          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_maxima_cantarire_365">
                Vârsta maximă Cântărire 365
              </label>
              <input
                type="number"
                min="0"
                id="varsta_maxima_cantarire_365"
                formControlName="varsta_maxima_cantarire_365"
                [ngClass]="{ 'is-invalid': submitted && (f.varsta_maxima_cantarire_365.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.varsta_maxima_cantarire_365.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_maxima_cantarire_365.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="varsta_minima_eliberare_cz">
                Vârsta minimă de eliberare a certificatului zootehnic
              </label>
              <input
                type="number"
                min="0"
                id="varsta_minima_eliberare_cz"
                formControlName="varsta_minima_eliberare_cz"
                placeholder="Introduceți vârsta minimă de eliberare a certificatului zootehnic"
                [ngClass]="{ 'is-invalid': submitted && f.varsta_minima_eliberare_cz.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.varsta_minima_eliberare_cz.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.varsta_minima_eliberare_cz.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Adeverință APIA</p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-3">
              <label for="data">
                Termenul de depunere a cererilor unice
              </label>
              <app-date-picker
                (dateChanged)="dataChangedTermenDepunere($event)"
                [selectedDate]="settingsForm.value.termen_depunere_cereri"
                [required]="true"
                [disabled]="!isSuperAdmin"
              >
              </app-date-picker>

              <div
                *ngIf="submitted && f.termen_depunere_cereri.errors"
                class="text-danger"
              >
                <div *ngIf="f.termen_depunere_cereri.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-3">
              <label for="data">
                Data nașterii minimă pentru vaci, condiția de 48 de luni
              </label>
              <app-date-picker
                (dateChanged)="dataChangedDNVaci48($event)"
                [selectedDate]="settingsForm.value.data_nasterii_min_vaci_48"
                [required]="true"
                [disabled]="!isSuperAdmin"
              >
              </app-date-picker>

              <div
                *ngIf="submitted && f.data_nasterii_min_vaci_48.errors"
                class="text-danger"
              >
                <div *ngIf="f.data_nasterii_min_vaci_48.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Controlul Performanțelor Producției</p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="form-group mb-3">
              <label for="operator_cpp">
                Operator C.P.P.
              </label>
              <input
                type="text"
                id="operator_cpp"
                formControlName="operator_cpp"
                placeholder="Introduceți operator C.P.P."
                [ngClass]="{ 'is-invalid': submitted && f.operator_cpp.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.operator_cpp.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.operator_cpp.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group mb-3">
              <label for="telefon_operator_cpp">
                Telefon Operator C.P.P.
              </label>
              <input
                type="text"
                id="telefon_operator_cpp"
                formControlName="telefon_operator_cpp"
                placeholder="Introduceți telefon operator C.P.P."
                [ngClass]="{ 'is-invalid': submitted && f.telefon_operator_cpp.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.telefon_operator_cpp.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.telefon_operator_cpp.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group mb-3">
              <label for="responsabil_cpp">
                Responsabil C.P.P.
              </label>
              <input
                type="text"
                id="responsabil_cpp"
                formControlName="responsabil_cpp"
                placeholder="Introduceți responsabil C.P.P."
                [ngClass]="{ 'is-invalid': submitted && f.responsabil_cpp.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
              <div
                *ngIf="submitted && f.responsabil_cpp.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.responsabil_cpp.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Atenționări</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-2">
            <div class="form-group mb-3">
              <label>
                Trimite email atenționări
              </label>
              <div>
                <ng-select
                  id="trimite_email_atentionari"
                  formControlName="trimite_email_atentionari"
                  [ngClass]="{
                    'is-invalid': submitted && (f.trimite_email_atentionari.errors),
                    'disabled': !isSuperAdmin
                  }"
                  [clearable]="false"
                >
                  <ng-option [value]="1">Da</ng-option>
                  <ng-option [value]="0">Nu</ng-option>
                </ng-select>
              </div>

              <div
                *ngIf="submitted && f.trimite_email_atentionari.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.trimite_email_atentionari.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_incepere_atentionari">
                Durata începere atenționări (zile)
              </label>
              <input
                type="number"
                min="0"
                id="durata_incepere_atentionari"
                formControlName="durata_incepere_atentionari"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_incepere_atentionari.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.durata_incepere_atentionari.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_incepere_atentionari.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_intre_atentionari">
                Durata între atenționări (zile)
              </label>
              <input
                type="number"
                min="0"
                id="durata_intre_atentionari"
                formControlName="durata_intre_atentionari"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_intre_atentionari.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.durata_intre_atentionari.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_intre_atentionari.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-3">
              <label for="durata_pastrare_atentionari">
                Durata păstrare atenționări (zile)
              </label>
              <input
                type="number"
                min="0"
                id="durata_pastrare_atentionari"
                formControlName="durata_pastrare_atentionari"
                [ngClass]="{ 'is-invalid': submitted && (f.durata_pastrare_atentionari.errors) }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />

              <div
                *ngIf="submitted && f.durata_pastrare_atentionari.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.durata_pastrare_atentionari.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <p class="settings-type">Email</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="titlu_email_acte_aditionale">
                Titlu email acte adiționale
              </label>
              <input
                type="text"
                id="titlu_email_acte_aditionale"
                formControlName="titlu_email_acte_aditionale"
                [ngClass]="{ 'is-invalid': submitted && f.titlu_email_acte_aditionale.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.titlu_email_acte_aditionale.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.titlu_email_acte_aditionale.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="titlu_email_atentionari_documente">
                Titlu email atenționări documente
              </label>
              <input
                type="text"
                id="titlu_email_atentionari_documente"
                formControlName="titlu_email_atentionari_documente"
                [ngClass]="{ 'is-invalid': submitted && f.titlu_email_atentionari_documente.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.titlu_email_atentionari_documente.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.titlu_email_atentionari_documente.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <div class="form-group mb-3">
              <label for="titlu_email_atentionari_fermier">
                Titlu email atenționări pentru fermier
              </label>
              <input
                type="text"
                id="titlu_email_atentionari_fermier"
                formControlName="titlu_email_atentionari_fermier"
                [ngClass]="{ 'is-invalid': submitted && f.titlu_email_atentionari_fermier.errors }"
                class="form-control"
                [readonly]="!isSuperAdmin"
              />
                <div
                  *ngIf="submitted && f.titlu_email_atentionari_fermier.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.titlu_email_atentionari_fermier.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>

      <div class="row">
        <ngb-alert
        type="success"
        class="text-center search-error mt-2"
        *ngIf="editSuccess"
        [dismissible]="false"
        >
          Datele au fost actualizate cu succes.
        </ngb-alert>
      </div>

      <div *ngIf="isSuperAdmin" class="row">
        <div class="col-12 text-right">
          <div class="btn-group my-2">
            <button
              class="btn btn-md btn-success py-1 px-3"
              (click)="onSubmitted()"
            >
              <i class="fe-save"></i>
              Salvează
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isSuperAdmin" class="card settings-card">
      <div class="row">
        <div class="col-12 mb-2">
          <h2>Template</h2>
        </div>
      </div>

      <div class="row">
        <div
          *ngFor="let template of templateArray; let index = index"
          class="col-sm-6 col-lg-3 mb-3"
        >
            <button
              type="button"
              class="btn btn-info btn-sm"
              (click)="openModalTemplate(index)"
            >
              {{ template.label }}
            </button>
        </div>
      </div>

      <app-template-acte-aditionale
        [data]="templateData"
      >
      </app-template-acte-aditionale>
    </div>
  </div>
</div>
