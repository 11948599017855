<ng-template #editBuletinModal let-modal="close('Cross click')">
  <div class="modal-header">
    <div class="cantariri-details">
      <span class="h4">
        Operator control
      </span>
      <ng-select
        [items]="operatori"
        bindLabel="full_name"
        bindValue="id"
        [(ngModel)]="cantaririDetails.userid"
        placeholder="Alege operatorul"
        class="operator"
      >
      </ng-select>
    </div>

    <div class="modal-title">
      <span class="h3">Buletin de control la {{ this.cantaririDetails.type }} luni</span>
      <span class="info ml-2">din {{ cantaririDetails.date }} ({{ count }} animale)</span>
    </div>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        {{isAutoritateJudeteana || isContabil? 'Închide': 'Anulează'}}
      </button>
      <button
        *ngIf="!isAutoritateJudeteana && !isContabil"
        class="btn btn-sm btn-success"
        [ngClass]="{disabled: count === 0}"
        (click)="onSubmitted()"
      >
        <span>
          Salvează buletin de control
        </span>
      </button>
    </div>
  </div>
  <ngb-alert
    type="danger"
    class="text-center search-error"
    *ngIf="count === 0"
    [dismissible]="false"
  >
    Nu a fost găsită nici o cântărire.
  </ngb-alert>
  <div class="modal-body" *ngIf="count > 0">
    <div class="container-fluid">
      <form
        name="cantaririForm"
        [formGroup]="cantaririForm"
        [ngClass]="{'disabled': isAutoritateJudeteana || isContabil}"
      >
        <div class="table-responsive" #buletineTable>
          <table
            id="buletine-datatable"
            class="table table-bordered dt-responsive nowrap table-hover"
            formArrayName="cantariri"
          >
            <thead>
              <tr>
                <th>
                  Animal cântărit
                </th>
                <th>
                  Greutate la naștere
                </th>
                <th>
                  Vârstă la cântărire
                </th>
                <th>
                  Greutate la data cântăririi
                </th>
                <th>
                  GVR
                </th>
                <th>
                  SMZ (G/ZI)
                </th>
                <th>
                  Destinație
                </th>
                <th>
                  Observații
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                [formGroupName]="i"
                *ngFor="
                  let item of cantaririForm['controls'].cantariri['controls'];
                  let i = index
                "
              >
                <td class="col_animal">
                  <a
                    [routerLink]="'/dashboard/animal/' + item.value.animalid + '/pedigree-rg'"
                    target="_blank"
                  >{{ item.value.numarmatricol }}</a>,
                  {{ item.value.sex_text }}, {{ item.value.rasa_text }}
                </td>

                <td class="col_value">
                  {{ item.value.greutate_nastere }}
                </td>

                <td class="col_value">
                  {{ item.value.varsta_cantarire }}
                </td>

                <td class="col_greutate">
                  <input
                    type="number"
                    placeholder="Adaugă greutate"
                    class="form-control"
                    name="weight"
                    formControlName="weight"
                    [readonly]="item.value.read_only"
                    (change)="weightChanged(i)"
                  />
                </td>

                <td class="col_value">
                  {{ item.value.gvr }}
                </td>

                <td class="col_value">
                  {{ item.value.smz }}
                </td>

                <td>
                  <ng-select
                    bindLabel="destinationid"
                    bindValue="destinationid"
                    formControlName="destinationid"
                  >
                    <ng-option [value]="1">Reproducție</ng-option>
                    <ng-option [value]="2">Carne</ng-option>
                  </ng-select>
                </td>

                <td>
                  <input
                    type="text"
                    placeholder="Adaugă observații"
                    class="form-control"
                    name="notes"
                    formControlName="notes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>

      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <app-upload-fisier
            (ApiResponse)="uploadServerResponse($event)">
          </app-upload-fisier>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-auto">

          <div *ngIf="cantaririDetails.file_id" class="form-group mb-3">
            <button
              type="button"
              (click)="downloadFile($event)"
              ngbTooltip="Download fișier"
              class="btn p-0 text-blue font-weight-bold"
            >
              {{ cantaririDetails.file_name }}
            </button>
            <button
              type="button"
              class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
              ngbTooltip="Șterge fișier"
              placement="right"
              (click)="deleteFile()"
            >
            </button>
          </div>

          <div *ngIf="showDeleteHint" class="text-danger">
            Modificarea mai trebuie salvată prin click pe butonul "Salvează buletin de control"
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-template>
