<h3>Acte adiționale</h3>

<ng-container *ngFor="let actAditional of acte_aditionale.controls; let index = index; let last = last">
  <ng-container [formGroup]="actAditional">
    <div class="d-flex flex-wrap mb-2">
      <div class="act_aditional_item_big">
        <span class="act_aditional_label">
          <label for="data">
            Data
          </label>
        </span>

        <app-date-picker
          [selectedDate]="actAditional.controls.data.value"
          (dateChanged)="dataActAditionalChanged(actAditional, $event)"
          [disabled]="!(isSuperAdmin || (last && permisiuneEditare))"
        >
        </app-date-picker>
      </div>

      <div class="act_aditional_item">
        <span class="act_aditional_label">
          <label for="nr_femele_rasa_pura">
            Nr. femele rasă pură
          </label>
        </span>

        <input
          type="number"
          class="form-control"
          formControlName="nr_femele_rasa_pura"
          [readonly]="!(isSuperAdmin || (last && permisiuneEditare))"
        />
      </div>

      <div class="act_aditional_item">
        <span class="act_aditional_label">
          <label for="nr_masculi_rasa_pura">
            Nr. masculi rasă pură
          </label>
        </span>

        <input
          type="number"
          class="form-control"
          formControlName="nr_masculi_rasa_pura"
          [readonly]="!(isSuperAdmin || (last && permisiuneEditare))"
        />
      </div>

      <div class="act_aditional_item_big">
        <span class="act_aditional_label">
          <label for="nr_femele_sect_suplim">
            Nr. femele înregistrate în sect. suplimentară
          </label>
        </span>

        <input
          type="number"
          class="form-control"
          formControlName="nr_femele_sect_suplim"
          [readonly]="!(isSuperAdmin || (last && permisiuneEditare))"
        />
      </div>

      <div class="mr-3">
        <span class="act_aditional_label">
        </span>

        <button
          type="button"
          class="btn btn-sm btn-info"
          ngbTooltip="Descarcă PDF"
          (click)="downloadPDF(actAditional.value.id)"
        >
          <i class="fe-download"></i>
        </button>
      </div>

      <div class="mr-3">
        <span class="act_aditional_label">
        </span>

        <button
          *ngIf="permisiuneEditare"
          type="button"
          class="btn btn-sm btn-blue"
          ngbTooltip="Trimite email"
          (click)="openTrimiteEmailModal(actAditional.value.id)"
        >
          <i class="mdi mdi-email"></i>
        </button>
      </div>

      <div class="mr-3">
        <span class="act_aditional_label">
          <label>
            Fișier
          </label>
        </span>

          <span
            *ngIf="actAditional.controls.file_name.value"
            class="cursor-pointer p-0 text-blue font-weight-bold"
            (click)="downloadFileActAditional(actAditional)"
          >
            <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
            {{ actAditional.controls.file_name.value }}
          </span>

        <div>
          <app-upload-fisier
            *ngIf="
              isSuperAdmin ||
              ((last || !actAditional.controls.file_name.value) && permisiuneEditare)
            "
            (ApiResponse)="uploadActAditional(actAditional, $event)"
          >
          </app-upload-fisier>
        </div>

      </div>

      <div
        *ngIf="isSuperAdmin || (last && permisiuneEditare)"
        class="pt-4 mr-3 position-relative"
      >
        <i
          class="fas fa-times-circle h3 text-danger"
          ngbTooltip="Șterge"
          placement="bottom"
          (click)="deleteActAditional(index)"
        ></i>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #trimiteEmailModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Trimite email cu Actul Adițional?
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <input
        type="text"
        [(ngModel)]="email_cont_exploatatie"
        class="form-control mb-3"
      />

      <ngb-alert
        *ngIf="successTrimiteEmail"
        type="success"
        class="mt-2"
      >
        Trimis cu succes!
      </ngb-alert>

      <ngb-alert
        *ngIf="errorTrimiteEmail"
        type="danger"
        class="mt-2"
      >
        Nu s-a putut trimite email-ul
      </ngb-alert>

      <button
        class="btn btn-dark mr-5"
        type="button"
        (click)="modal('Cross click')"
      >
        Anulează
      </button>

      <button
        type="button"
        class="btn btn-sm btn-blue"
        ngbTooltip="Trimite email"
        (click)="trimiteEmail()"
      >
        Trimite email
      </button>

    </div>
  </div>
</ng-template>
