<div class="container-fluid  pt-4">

  <div class="row">
    <div class="col-sm-12">

      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>ANEXA ÎNREGISTRĂRI I.A./M.N.</th>
              <th class="text-center">Stare</th>
              <th class="text-center">Acțiuni</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let anexa of anexe8">
              <td>{{anexa.number}} / {{anexa.datecreated}}</td>

              <td
                class="text-center"
                [ngSwitch]="anexa.status"
              >
                <span *ngSwitchCase="0">În așteptare</span>
                <span *ngSwitchCase="1">Verificare</span>
                <span *ngSwitchCase="2">Operare</span>
                <span *ngSwitchCase="3">Validat</span>
                <span *ngSwitchCase="4">Refuzat</span>
                <span *ngSwitchCase="5">Anulat</span>
                <span *ngSwitchDefault>?</span>
              </td>

              <td class="action-buttons text-center">
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-icon btn-success"
                    title="Editează"
                    (click)="openAnexa8(anexa.id)"
                  >
                    <i class="mdi mdi-square-edit-outline"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    (click)="viewAnexa8(anexa)"
                  >
                    <span class="btn-label">
                      <i class="fe-eye"></i>
                    </span>
                    Vizualizează
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-info"
                    (click)="downloadAnexa8(anexa)"
                  >
                    <span class="btn-label">
                      <i class="fe-download"></i>
                    </span>
                    Descarcă PDF
                  </button>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>

    </div>
  </div>
</div>

<app-rep-fat-modal
  [data]="modalData"
  (callbackResult)="getModalResult($event)"
>
</app-rep-fat-modal>
