<div class="card" id="locatii" [formGroup]="holdingForm">
    <h3 class="card-header">Locații</h3>
    <div class="card-body">

        <ng-container formArrayName="locatii">
            <ng-container *ngFor="let locatieForm of locatii.controls; let index = index">
                <ng-container [formGroup]="locatieForm">

                    <div class="row mb-3">
                        <div class="mr-2">
                            <label for="nume">
                              Nume locație
                            </label>

                            <div class="d-flex">
                                <ng-select
                                    placeholder="Alege"
                                    class="select-nume"
                                    [items]="numeLocatii[index].items"
                                    [(ngModel)]="numeLocatii[index].value"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="changedNume($event, locatieForm)"
                                >
                                </ng-select>

                                <input
                                    *ngIf="numeLocatii[index].afiseaza"
                                    type="text"
                                    formControlName="nume"
                                    class="form-control nume"
                                />
                            </div>

                            <div
                                *ngIf="submitted && locatieForm.controls.nume.errors?.required"
                                class="invalid-feedback d-block"
                            >
                                Lipsește numele
                            </div>
                        </div>

                        <div class="mr-2 coord">
                            <label for="lat">
                                Latitudine
                            </label>

                            <input
                                type="text"
                                formControlName="lat"
                                class="form-control"
                            />

                            <div
                                *ngIf="submitted && locatieForm.controls.lat.errors?.required"
                                class="invalid-feedback d-block"
                            >
                                Lipsește latitudinea
                            </div>
                        </div>

                        <div class="mr-2 coord">
                            <label for="lng">
                                Longitudine
                            </label>

                            <input
                                type="text"
                                formControlName="lng"
                                class="form-control"
                            />

                            <div
                                *ngIf="submitted && locatieForm.controls.lng.errors?.required"
                                class="invalid-feedback d-block"
                            >
                                Lipsește longitudinea
                            </div>
                        </div>

                        <div class="mr-2">
                            <label>&nbsp;</label>
                            <div class="form-control border-0">
                                <a href="https://www.google.com/maps/search/?api=1&query={{locatieForm.value.lat}},{{locatieForm.value.lng}}" target="_blank">
                                    Locația pe hartă
                                </a>
                            </div>
                        </div>

                        <div class="mr-2">
                            <label>&nbsp;</label>
                            <div class="form-control border-0 p-0">
                                <button
                                    class="btn btn-blue btn-sm"
                                    type="button"
                                    (click)="setCurrentLocation(locatieForm)"
                                >
                                    Adaugă locația curentă
                                </button>
                            </div>
                        </div>

                        <div class="mr-2">
                            <label>&nbsp;</label>
                            <div class="form-control border-0 p-0">
                                <i
                                    class="fas fa-times-circle h3 m-1 text-danger"
                                    ngbTooltip="Șterge locația"
                                    placement="bottom"
                                    (click)="stergeLocatie(index)"
                                ></i>
                            </div>
                        </div>

                        <div
                            *ngIf="locatieForm.errors?.bad_format"
                            class="invalid-feedback d-block"
                        >
                            Locație invalidă
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <div class="row mb-3">
            <div class="col-sm-12">
                <button
                    class="btn btn-success btn-sm"
                    type="button"
                    (click)="adaugaLocatie()"
                >
                    <i class="fa fa-plus-circle"></i>
                    Adaugă Locație
                </button>
            </div>
        </div>

    </div>
</div>
