import { Component, OnInit, Input, ViewChildren, QueryList, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { BirthsTableService } from 'src/app/modules/acbcr/common/services/births-table.service';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { takeUntil } from 'rxjs/operators';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-births-table',
  templateUrl: './births-table.component.html',
  styleUrls: ['./births-table.component.scss']
})
export class BirthsTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective>;
  @ViewChild('birthsTable', { static: false }) birthsTable: ElementRef;

  @Input() births = [];
  @Input() birthsLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() birthTableCallback = new EventEmitter();

  readonly modalType = 'birth';
  openSearchModal = new Subject<boolean>();
  modalData;

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentRoute: string;
  isAutoritateJudeteana = false;
  isContabil = false;

  constructor(
    public birthsTableService: BirthsTableService,
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit(): void {
    // Read current app route from the store
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  openFatariModal() {
    this.modalData = {
      'anexa_id': null,
      'modal_type': 'fatari',
    };
  }

  openAnexa8(anexa8Id) {
    this.modalData = {
      'anexa_id': anexa8Id,
      'modal_type': 'fatari',
    };
  }

  getModalResult(event) {
    this.birthTableCallback.emit(event);
  }
}
