import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IncadrareSectiuneService } from 'src/app/modules/acbcr/common/services/incadrare-sectiune.service'
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-incadrare-sectiune',
  templateUrl: './incadrare-sectiune.component.html',
  styleUrls: ['./incadrare-sectiune.component.scss']
})
export class IncadrareSectiuneComponent implements OnInit {
  baseUrl = environment.API_URL;
  uploadConfig = {
    multiple: false,
    maxSize: '100',
    formatsAllowed: '.csv',
    uploadAPI: {
      url: `${this.baseUrl}/files/upload-tmp/`,
      headers: {
        Authorization: `Bearer ${localStorage['access-token']}`
      }
    },
    autoUpload: true,
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Selectați documentul',
      resetBtn: 'Resetează',
      uploadBtn: 'Încarcă documentul',
      afterUploadMsg_success: 'Încărcare reușită! Începe procesarea!',
      afterUploadMsg_error: 'Încărcare eșuată!'
    }
  };
  intervalID: number;
  REFRESH_INTERVAL = 5000;
  statsInCurs = null;
  uniqueId = UIHelper.generateUniqueId();

  constructor(
    public incadrareSectiuneService: IncadrareSectiuneService,
    public errorService: NotificationErrorService,
  ) { }

  ngOnInit(): void {
    this.intervalID = window.setInterval(this.updateStatsInCurs.bind(this), this.REFRESH_INTERVAL);
  }

  onFileSelected(event) {
    const fisier:File = event.target.files[0];
    const formData = new FormData();
    formData.append('fisier', fisier);
    formData.append('unique_id', this.uniqueId);

    this.incadrareSectiuneService.uploadCSVFile(formData)
      .subscribe({
        next: (response) => {
          return response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  updateStatsInCurs() {
    this.incadrareSectiuneService.inCursAPI(this.uniqueId).subscribe((response) => {
      if (response) {
        this.statsInCurs = response;
        this.ngOnDestroy();
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalID) {
      window.clearInterval(this.intervalID)
    }
  }
}
