<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="holdingTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >

               <button
                  *ngIf="!isFermier"
                  class="btn btn-info btn-sm"
                  [ngClass]="{ 'btn-warning': isFiltered }"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal('holding')"
                >
                  <i class="fe-search"></i>
                  <span *ngIf="isFiltered; else filtreaza"> Schimbă filtrul</span>
                  <ng-template #filtreaza>
                    <span> Filtrează</span>
                  </ng-template>
                </button>


                <button
                  *ngIf="isAdmin"
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  (click)="openAddEditHoldingModal('add')"
                >
                  <i class="fe-save"></i>
                  Adaugă exploatație
                </button>
                <button
                  *ngIf="!isAutoritateJudeteana && !isContabil"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive" #holdingsTable>
            <table
              id="exploatatii-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="code" (sort)="onSort($event)">
                    COD EXPLOATAȚIE
                  </th>
                  <th sortable="name" (sort)="onSort($event)">
                    EXPLOATAȚIE
                  </th>
                  <th sortable="cif" (sort)="onSort($event)">
                    CUI/CNP
                  </th>
                  <th sortable="judet" (sort)="onSort($event)">
                    JUDEȚ
                  </th>
                  <th>
                    RASELE EXPLOATAȚIEI
                  </th>
                  <th sortable="count_animals" (sort)="onSort($event)">
                    NUMĂR ANIMALE
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    STARE
                  </th>
                  <th scope="col">
                    ACȚIUNI
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="holdingTableService.totalRecords === 0 && onlySearch"
                [dismissible]="false"
              >
                Nu a fost găsită nici o exploatație în urma căutări dvs.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              >
              </div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner}">
                <tr tr *ngFor="let exploatatie of tables$ | async">
                  <td> {{exploatatie.code}} </td>
                  <td>
                    <a
                      (click)="onHoldingNameClick(exploatatie)"
                      [routerLink]="'/dashboard/exploatatie/' + exploatatie.code +'/animale-exploatatie'"
                      ngbTooltip="Afișează detalii exploatație"
                      class="underline-link"
                    > {{ exploatatie.name }}
                    </a>
                  </td>
                  <td> {{ exploatatie.cif }} </td>
                  <td> {{ exploatatie.judet }} </td>
                  <td> {{ exploatatie.racecontract }} </td>
                  <td> {{ exploatatie.count_animals}} </td>
                  <td [ngSwitch]="exploatatie.status">
                    <span *ngSwitchCase="1">Activ</span>
                    <span *ngSwitchCase="0">Inactiv</span>
                    <span *ngSwitchCase="-1">Așteptare</span>
                    <span *ngSwitchCase="5">Import</span>
                  </td>
                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        *ngIf="!isAutoritateJudeteana && !isContabil; else view"
                        class="btn btn-sm btn-icon btn-success"
                        title="Editează"
                        ngbTooltip="Editează exploatația"
                        placement="bottom"
                        (click)="openAddEditHoldingModal('edit', exploatatie.code)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>

                      <ng-template #view>
                        <button
                          class="btn btn-sm btn-icon btn-success"
                          title="Vizualizează"
                          ngbTooltip="Vizualizează exploatația"
                          placement="bottom"
                          (click)="openAddEditHoldingModal('view', exploatatie.code)"
                        >
                          <i class="mdi mdi-eye-outline"></i>
                        </button>
                      </ng-template>

                      <button
                        class="btn btn-sm btn-icon btn-warning"
                        title="Descarca"
                        ngbTooltip="Descarcă istoricul exploatației"
                        placement="bottom"
                        (click)="downloadHoldingHistory(exploatatie.id, exploatatie.code)"
                      >
                        <i class="mdi mdi-file-download"></i>
                      </button>

                      <button
                        *ngIf="isAdmin"
                        class="btn btn-sm btn-icon btn-danger"
                        title="Șterge"
                        ngbTooltip="Șterge exploatația"
                        placement="bottom"
                        (click)="openConfirmActionModal(exploatatie.code, exploatatie.id)"
                      >
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="holdingTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ holdingTableService.startIndex }} la {{ holdingTableService.endIndex }} din
                {{ holdingTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  *ngIf="total$ | async"
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="total$ | async"
                  [(page)]="holdingTableService.page"
                  [pageSize]="holdingTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-update-holding
  [open]="openAddEditModal"
  (callbackResult)="updateHoldingsList($event)"
  (notifyClose)="closeAddUpdateModal($event)"
  [modalType]="modalType"
  [holdingData]="holdingData"
>
</app-add-update-holding>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-holding'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
