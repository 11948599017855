<div class="rg-section">
  <div class="separator mb-3"></div>

  <div class="row">
    <div class="col-12">
      <p class="section-subtitle">
        Încadrare secțiune Registru Genealogic
      </p>
    </div>
  </div>

  <div class="col-sm-12 col-md-6">
    <div class="form-group mb-3">
      <input
        #fileUpload
        type="file"
        accept=".csv"
        class="file-input"
        style="display: none"
        (change)="onFileSelected($event)"
      />

      <button
        class="btn btn-info"
        (click)="fileUpload.click()"
      >
        Alege fișierul
      </button>
    </div>
  </div>

  <div class="col-12" *ngIf="statsInCurs">
    <div [ngSwitch]="statsInCurs?.success">
      <span
        *ngSwitchCase="true"
        class="text-success"
      >Succes</span>
      <span
        *ngSwitchCase="false"
        class="text-danger"
      >Eroare la rulare</span>
    </div>
    <div>Nr. total animale: {{ statsInCurs?.nr_total_animale }}</div>
    <div>Nr. animale procesate: {{ statsInCurs?.nr_animale_procesate }}</div>

    <div class="mt-1 text-danger">Erori:</div>
    <div
      *ngFor="let error of statsInCurs?.error_list"
      class="text-danger"
    >
      {{ error }}
    </div>

    <div class="mt-1 text-success">Succes:</div>
    <div
      *ngFor="let error of statsInCurs?.success_list"
      class="text-success"
    >
      {{ error }}
    </div>

  </div>
</div>
