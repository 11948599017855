<!-- Topbar Start -->
<div class="navbar-custom">
  <ul class="list-unstyled topnav-menu float-right mb-0">
    <li ngbDropdown placement="bottom-end">
      <a
        class="nav-link dropdown-toggle waves-effect waves-light"
        (click)="onClickClopotel()"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
        ngbDropdownToggle
        id="notificationDropdown"
      >
        <i
          class="fe-bell noti-icon"
          [style.color]="necitite > 0 ? '#f1556c' : ''"
        >
        </i>
        <span
          class="badge badge-danger rounded-circle noti-icon-badge"
          *ngIf="necitite > 0"
        >
          {{ necitite }}
        </span>
      </a>

      <div
        class="dropdown-notification dropdown-menu dropdown-menu-end"
        aria-labelledby="notificationDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        <div class="dropdown-item dropdown-notification-title" ngbDropdownItem>
          <span
            class="cursor-pointer"
            ngbTooltip="Descarcă notificări"
            placement="right"
            (click)="downloadNotificari()"
          >
            Notificări
            <i class="fe-download pr-2"></i>
          </span>
        </div>

        <div
          id="notification-items"
          class="dropdown-notification-scroll"
        >
          <!-- item-->
          <div
            *ngFor="let notification of notificationItems"
            [ngClass]="{'notification-necitit': ! notification.citit}"
            ngbDropdownItem
          >
              <a
                [routerLink]="notification.link_documente"
                (click)="onClickNotification(notification)"
              >
                {{ notification.text }}
              </a>
          </div>

          <div
            *ngIf="!notificationItems || notificationItems.length == 0"
            class="text-center"
          >
            Nici o notificare de afișat.
          </div>
        </div>

      </div>
    </li>

    <li ngbDropdown>
      <a
        class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        ngbDropdownToggle
        id="profileDropdown"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <img
          src="assets/images/users/no-img.png"
          alt="user-image"
          class="rounded-circle"
        />
        <span class="pro-user-name ml-1">
          <span *ngIf="username; else user">{{
            username
          }}</span
          ><i class="mdi mdi-chevron-down"></i>
          <ng-template #user>
            <span>User</span>
          </ng-template>
        </span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-dropdown"
        aria-labelledby="profileDropdown"
        ngbDropdownMenu
      >
        <a
          class="dropdown-item drop-item"
          (click)="logout()"
        >
          <i class="fe-log-out"></i>
          <span>Deconectare</span>
        </a>
      </div>
    </li>
  </ul>

  <!-- LOGO -->
  <div class="logo-box" ngbDropdown>
    <a
      class="logo text-right dropdown-toggle"
      ngbDropdownToggle
      id="menuDropdown"
      href="javascript: void(0);"
      role="button"
      aria-haspopup="false"
      aria-expanded="false"
    >
      <span class="logo-lg">
        <img src="assets/images/logo.png" />
      </span>
      <span class="logo-sm">
        <img src="assets/images/logo.png" />
      </span>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right menu-dropdown"
      aria-labelledby="menuDropdown"
      ngbDropdownMenu
    >
      <a
        href="javascript:void(0);"
        class="dropdown-item drop-item"
        routerLink="/dashboard/terms"
      >
        <span>Termeni si conditii</span>
      </a>

      <a
        href="javascript:void(0);"
        class="dropdown-item drop-item"
        routerLink="/dashboard/intrebari-frecvente"
      >
        <span>Intrebari frecvente</span>
      </a>

      <a
        href="javascript:void(0);"
        class="dropdown-item drop-item"
        routerLink="/dashboard/contact"
      >
        <span>Contact</span>
      </a>
    </div>
  </div>

  <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
    <li>
      <button
        class="button-menu-mobile waves-effect waves-light"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fe-menu"></i>
      </button>
    </li>
  </ul>

  <div class="top-links">
    <div>
      <a href="https://lpis.apia.org.ro" target="_blank">Cererea de plată APIA</a>
      <a href="https://anunturi.acbcr.ro" target="_blank">Piața bovinelor online</a>
    </div>

    <div>
      <a href="https://acbcr.ro" target="_blank">ACBCR</a>
      <a href="https://acbcr.ro/despre/" target="_blank">Despre noi</a>
    </div>

    <div class="contact">
      <a class="text-wrap" href="mailto:asociatia@acbcr.ro">Contact: asociatia@acbcr.ro</a>
    </div>
  </div>
</div>
<!-- end Topbar -->
