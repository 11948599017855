import { NotificationErrorService } from './../../../../../../../common/services/notification-error.service';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Country } from 'src/app/modules/acbcr/common/models/country.model';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCountries } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { RegistruGenealogicTableService } from 'src/app/modules/acbcr/common/services/registru-genealogic-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-registru-genealogic-form-block',
  templateUrl: './registru-genealogic-form-block.component.html',
  styleUrls: ['./registru-genealogic-form-block.component.scss']
})
export class RegistruGenealogicFormBlockComponent implements OnInit, OnChanges {
  @ViewChild('popover') popover;
  @Input() animalForm: UntypedFormGroup;
  @Input() modalType: string;
  @Input() submitted: boolean;
  @Input() disableAllFields: boolean;
  @Output() updateFields = new EventEmitter();
  @Output() disableSubmit = new EventEmitter();
  @Output() updateAnimal = new EventEmitter();

  reasonModalData = {};
  countries: Country[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAdmin = false;
  isSuperAdmin = false;
  isOperator = false;
  isFermier = false;
  userEmail = '';
  initialSectionValue;
  initialGenSectionValue;
  errorMessage;

  rgRequired = false;
  rgDataDisabled = false;
  rgNrDisabled = false;

  popoverOpened = false;
  sectionChanged = false;
  generalSectionChanged = false;
  fitMessage;

  constructor(
    public registruGenealogicTableService: RegistruGenealogicTableService,
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>,
    private animalTableService: AnimalsTableService,
    private errorService: NotificationErrorService
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.userEmail = UIHelper.userEmail();
    this.store
      .pipe(select(getAppCountries))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: Country[]) => {
        this.countries = response;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.animalForm && this.animalForm.value.id) {
      this.animalTableService
        .getAnimalRegGen(this.animalForm.value.id)
        .subscribe(response => {
          const foundOriginCountryId = this.countries.filter(
            country => country.id === response.taraorigineid
          );
          const foundProvCountryId = this.countries.filter(
            country => country.id === response.taraprovenientaid
          );

          if (foundOriginCountryId) {
            this.f.taraorigineid.setValue(response.taraorigineid);
          }
          if (foundProvCountryId) {
            this.f.taraprovenientaid.setValue(response.taraprovenientaid);
          }

          this.f.nrregoriginal.setValue(response.nrregoriginal);
          this.f.sectreggeneral.setValue(response.sectreggeneral !== null ? response.sectreggeneral.toString() : '0');
          this.f.sectregro.setValue(response.sectregro !== null ? response.sectregro.toString() : '0');
          this.initialSectionValue = this.f.sectregro.value;
          this.initialGenSectionValue = this.f.sectreggeneral.value;
          this.f.nrregro.setValue(response.nrregro);
          this.f.data_rg.setValue(response.data);

          if (response.data) {
            this.rgDataDisabled = true;
          }
          if (response.nrregro) {
            this.rgNrDisabled = true;
          }

          this.checkRgValidators();
        }, error => this.errorService.processErrorMsg(error.error.error));
    }
  }

  dataRgChanged(event) {
    this.animalForm.controls.data_rg.setValue(event);
  }

  rgGenSectionChanged(event) {
    this.handleSectionUnchange();
    if (this.initialGenSectionValue !== event) {
      this.generalSectionChanged = true;
      this.disableSubmit.emit(true);
    }
  }

  rgSectionChanged(event) {
    this.updateFields.emit(event);
    this.handleSectionUnchange();
    const section = parseInt(event, 10);
    if (section.toString() !== this.initialSectionValue && this.animalForm.value.id) {
      this.checkRgSection(this.animalForm.value.id, section);
    }
    if (section === 0 && this.animalForm.value.id) {
      this.checkRgValidators();
    }
  }

  viewAfectedDescendants() {
    if (this.popoverOpened) {
      return;
    }

    this.registruGenealogicTableService.simulateFitDescendants(
      this.f.numarmatricol.value, this.userEmail,
       this.generalSectionChanged ? this.f.sectreggeneral.value : null, this.sectionChanged ? this.f.sectregro.value : null)
      .subscribe(response => {
        this.fitMessage = response;
        this.popoverOpened = true;
        this.popover.open();
    });
  }

  cancelUpdateDescendants() {
    this.handleSectionUnchange();
    this.animalForm.controls.sectregro.setValue(this.initialSectionValue);
    this.animalForm.controls.sectreggeneral.setValue(this.initialGenSectionValue);
    this.checkRgValidators();
  }

  updateDescendants() {
    if (this.sectionChanged) {
      this.initialSectionValue = this.f.sectregro.value;
      this.fitDescendants();
    } else if (this.generalSectionChanged) {
      this.fitDescendants();
      this.initialGenSectionValue = this.f.sectreggeneral.value;
      this.generalSectionChanged = false;
    }
  }

  private fitDescendants() {
    this.registruGenealogicTableService.fitDescendants(
      this.f.numarmatricol.value, this.userEmail,
      this.generalSectionChanged ? this.f.sectreggeneral.value : null, this.sectionChanged ? this.f.sectregro.value : null)
      .subscribe(response => {
        this.updateAnimal.emit(false);
        this.handleSectionUnchange();
        if (!(response.status === 204)) {
          const blob = new Blob([response.body], { type: 'application/octet-stream' });
          this.helperDataService.simulateDownload(blob, this.f.numarmatricol.value + '_descendenti.xls');
        }
    });
  }

  private handleSectionUnchange() {
    this.popoverOpened = false;
    this.sectionChanged = false;
    this.generalSectionChanged = false;
    this.disableSubmit.emit(false);
    this.popover?.close();
  }

  private checkRgSection(animalId, section) {
    this.registruGenealogicTableService.checkRgSection(animalId, section)
      .subscribe(() => {
        this.sectionChanged = true;
        this.checkRgValidators();
        this.autoFillDateNumberRG();
        this.disableSubmit.emit(true);
      }, error => {
        this.errorMessage = error.error.error;
        this.sectionChanged = false;
        this.disableSubmit.emit(false);
        setTimeout(() => {
          this.animalForm.controls.sectregro.setValue(this.initialSectionValue);
          this.errorMessage = null;
        }, 4000);
      });
  }

  viewReasons(animalId) {
    this.reasonModalData = {'animal_id': animalId};
  }

  private checkRgValidators() {
    const rg = this.animalForm.value;
    if (rg.sectregro && rg.sectregro !== '0') {
      this.rgRequired = true;
      this.f.data_rg.setValidators(Validators.required);
      this.f.nrregro.setValidators(Validators.required);
    } else {
      this.rgRequired = false;
      this.f.data_rg.clearValidators();
      this.f.nrregro.clearValidators();
    }

    this.f.data_rg.updateValueAndValidity();
    this.f.nrregro.updateValueAndValidity();
  }

  private autoFillDateNumberRG() {
    if (!this.f.data_rg.value) {
      this.f.data_rg.setValue(UIHelper.getCurrentDate());
    }
    if (!this.f.nrregro.value) {
      this.f.nrregro.setValue(this.f.numarmatricol.value);
    }
  }

  get f() { return this.animalForm.controls; }
}
