<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="animalTableService.pageSize"
                    (change)="changeTableRows($event)"
                    [disabled]="loadingDataSpinner"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  [ngClass]="{ 'btn-warning': isFiltered }"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal('animal')"
                >
                  <i class="fe-search"></i>
                  <span *ngIf="isFiltered; else filtreaza"> Schimbă filtrul</span>
                  <ng-template #filtreaza>
                    <span> Filtrează</span>
                  </ng-template>
                </button>
                <button
                *ngIf="isAdmin || isOperator"
                  class="btn btn-blue btn-sm"
                  type="button"
                  [disabled]="loadingDataSpinner"
                  (click)="openAddEditAnimalModal('add')"
                >
                  <i class="fe-save"></i>
                  Adaugă animal
                </button>
                <button
                  *ngIf="isAdmin || isOperator || isFermier"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive" #animalsTable>
            <table
              id="animale-datatable"
              class="table datatables dt-responsive nowrap"
            >
              <thead>
                <tr>
                  <th sortable="numarmatricol" (sort)="onSort($event)">
                    NUMĂR MATRICOL
                  </th>
                  <th sortable="rasa" (sort)="onSort($event)">
                    RASĂ
                  </th>
                  <th sortable="codtaur" (sort)="onSort($event)">
                    COD TAUR
                  </th>
                  <th sortable="nume" (sort)="onSort($event)">
                    NUME
                  </th>
                  <th sortable="sex" (sort)="onSort($event)">
                    SEX
                  </th>
                  <th sortable="datanastere" (sort)="onSort($event)">
                    DATA NAȘTERII
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    STARE
                  </th>
                  <th scope="col">ACȚIUNI</th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="animalTableService.totalRecords === 0 && onlySearch"
                [dismissible]="false"
              >
                Nu a fost găsit nici un animal în urma căutării dvs.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>
              <tbody [ngClass]="{ disabled: loadingDataSpinner}">
                <tr tr *ngFor="let animal of tables$ | async; let last = last">
                  <td>
                    <a
                      class="underline-link"
                      [routerLink]="'/dashboard/animal/' + animal.id + '/pedigree-rg'"
                      (click)="onAnimalMatricolClick(animal)"
                    > {{animal.numarmatricol}}
                    </a>
                  </td>
                  <td> {{animal.rasa_shortname}} </td>
                  <td> {{animal.codtaur}} </td>
                  <td> {{animal.nume}} </td>
                  <td>
                    <span *ngIf="animal.sex == 1; else femela">
                      Mascul
                    </span>

                    <ng-template #femela>
                      <span>Femelă</span>
                    </ng-template>
                  </td>
                  <td> {{animal.datanastere}} </td>
                  <td>
                    <span *ngIf="animal.status == 1; else inactiv">
                      Activ
                    </span>

                    <ng-template #inactiv>
                      <span>Inactiv</span>
                    </ng-template>
                  </td>
                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        *ngIf="isAdmin || isOperator || isFermier; else view"
                        class="btn btn-sm btn-icon btn-success"
                        (click)="openAddEditAnimalModal('edit', animal.id)"
                        ngbTooltip="Editează animal"
                        [placement]="last ? 'top' : 'bottom'"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>
                      <ng-template #view>
                        <button
                          class="btn btn-sm btn-icon btn-success"
                          (click)="openAddEditAnimalModal('view', animal.id)"
                          ngbTooltip="Vizualizează animal"
                          [placement]="last ? 'top' : 'bottom'"
                        >
                          <i class="mdi mdi-eye-outline"></i>
                        </button>
                      </ng-template>

                      <button
                        type="button"
                        class="btn btn-sm btn-info"
                        ngbTooltip="Fișă animal"
                        [placement]="last ? 'top' : 'bottom'"
                        (click)="generateFisaAnimal(animal.id)"
                      >
                        <i class="fe-download"></i>
                      </button>
                      <button
                        *ngIf="isAdmin"
                        class="btn btn-sm btn-icon btn-danger"
                        (click)="openConfirmActionModal(animal.id)"
                        ngbTooltip="Șterge animal"
                        [placement]="last ? 'top' : 'bottom'"
                      >
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ animalTableService.startIndex }} la
                {{ animalTableService.endIndex }} din
                {{ animalTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  *ngIf="total$ | async"
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="total$ | async"
                  [(page)]="animalTableService.page"
                  [pageSize]="animalTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-update-animal
  [open]="openAddEditModal"
  (notifyClose)="closeAddUpdateModal($event)"
  (animalAdded)="onAnimalAdded($event)"
  [modalType]="modalType"
  [animalData]="animalData"
>
</app-add-update-animal>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
></app-table-search-modal>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-animal'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
