<ng-template #addEditTemplate let-modal="close('Cross click')">
  <div class="modal-header row" [ngSwitch]="addEditModalType">
    <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Creare avizare taur
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează avizare taur
      </h3>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-secondary"
          (click)="closeModal()"
          autofocus="false"
        >
          Închide
        </button>
        <button
          class="btn btn-sm btn-success"
          (click)="onSubmitted()"
        >
          <span *ngIf="addEditModalType === 'add'; else btnTxt">
            Crează avizare taur
          </span>
          <ng-template #btnTxt>
            <span>
              Salvează avizare taur
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>

  <div class="modal-body h-100">
    <form [formGroup]="avizareForm" class="h-100">
      <div class="container-fluid">

        <div class="row">
          <div class="col-12 d-flex">
            <div class="form-group mb-3 mr-5">
              <label for="data">
                Data avizării
              </label>
              <app-date-picker
                (dateChanged)="onDateChanged($event)"
                [submitted]="submitted"
                [required]="true"
                [selectedDate]="avizareForm.controls.data.value"
                [disabled]="!isSuperAdmin && avizareForm.value.status && avizareForm.value.status != 1"
              >
              </app-date-picker>
            </div>

            <div class="form-group mb-3">
              <label for="numar">
                Număr
              </label>
              <input
                type="text"
                formControlName="numar"
                pattern="[0-9]+"
                class="numar form-control"
                [readonly]="!isSuperAdmin && avizareForm.value.status && avizareForm.value.status != 1"
              />

              <div
                *ngIf="submitted && avizareForm.controls.numar.errors?.pattern"
                class="invalid-feedback d-block"
              >
                Cămpul trebuie să fie un număr
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <h3 class="type-title">Exploatații</h3>
          </div>
        </div>

        <div class="row">
          <ng-container *ngFor="let exploatatieForm of avizareForm.controls.exploatatii.controls; let index = index">
            <div class="col-xl-9">
              <div [formGroup]="exploatatieForm" class="form-group mb-3">
                <ng-select
                  [items]="selectItemsExploatatii[index]"
                  bindLabel="viewLabel"
                  bindValue="id"
                  formControlName="id"
                  placeholder="Alege exploatația"
                  (change)="findAnimals()"
                  (search)="searchExploatatii($event, index)"
                  (clear)="clearHoldings()"
                >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <div class="row">
                      <div class="col-md-12 col-lg-auto">
                        {{item.name}}
                        <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                        <span *ngIf="item.status !== 1" class="badge bg-secondary">Inactiv</span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <small class="form-text text-muted ml-2">
                  <b>Introduceți minim 5 caractere pentru a căuta</b>
                </small>
              </div>
            </div>

            <div class="col-auto mb-3">
              <app-date-picker
                [selectedDate]="exploatatieForm.value.data_valabilitate"
                (dateChanged)="exploatatieForm.controls.data_valabilitate.setValue($event)">
              </app-date-picker>

              <small class="form-text text-muted ml-2">
                <b>Dată valabilitate</b>
              </small>
            </div>

            <div class="col-auto">
              <i
                class="fas fa-times-circle h3 text-danger"
                ngbTooltip="Șterge"
                placement="bottom"
                (click)="clickStergeExploatatie(index)"
              ></i>
            </div>
          </ng-container>
        </div>

        <div *ngIf="isAdmin" class="row">
          <div class="col-sm-12">
            <button
              class="btn btn-success btn-sm"
              type="button"
              (click)="adaugaExloatatie()"
            >
              <i class="fa fa-plus-circle"></i>
              Adaugă Exploatație
            </button>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div *ngIf="submitted && showErrorExploatatii" class="col-sm-12 text-danger">
            Nu a fost selectată nici o exploatație
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <h3 class="type-title">Taur</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3">
              <ng-select
                [items]="tauri"
                formControlName="taur_id"
                bindLabel="numarmatricol"
                bindValue="id"
                placeholder="Alege animal"
                [ngClass]="{'disabled': !isSuperAdmin && avizareForm.value.status && avizareForm.value.status != 1}"
              >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="row">
                    <div class="col-sm-12 col-md-auto">
                      <span>
                        {{ item.numarmatricol }}
                      </span>
                    </div>

                    <div class="col-sm-12 col-md-auto px-0 d-flex flex-wrap">
                      <span *ngIf="item.eligibil" class="mr-2 text-success">Eligibil</span>
                      <span *ngIf="item.are_avizare" class="badge badge-primary p-1 mr-2">Are avizare</span>
                      <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                        {{ error }}
                      </span>
                      <span *ngFor="let warning of item.warnings" class="badge badge-warning text-dark p-1 mr-2">
                        {{ warning }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                *ngIf="! avizareForm.value.data
                  || avizareForm.value.exploatatii.length == 0
                "
                class="form-text text-muted ml-2"
              >
                <b>Taurul se poate alege după ce s-a ales data și exploatația</b>
              </small>

              <div
                *ngIf="submitted && avizareForm.controls.taur_id.errors?.required"
                class="invalid-feedback d-block"
              >
                Completarea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
 </ng-template>
