<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="birthsTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openFatariModal()"
                  [disabled]="loadingDataSpinner"
                  *ngIf="currentRoute.includes('/exploatatie/') && !isAutoritateJudeteana && !isContabil"
                >
                  <i class="fe-save"></i>
                  Adaugă fătare (Anexa 8)
                </button>
                <button
                  *ngIf="!isAutoritateJudeteana && !isContabil"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner || birthsLength === 0"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #birthsTable>
            <table
              id="births-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Matricol vițel</th>
                  <th>Nume vițel</th>
                  <th>Sex vițel</th>
                  <th>Mama</th>
                  <th>Tata</th>
                  <th>Rang fătare</th>
                  <th>Ușurință fătare</th>
                  <th>Anexa 8</th>
                </tr>
              </thead>

              <div
                *ngIf="loadingDataSpinner"
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
              >
              </div>

              <ngb-alert
                *ngIf="!loadingDataSpinner && birthsTableService.totalRecords === 0"
                type="danger"
                class="text-center search-error"
                [dismissible]="false"
              >
                Nu a fost găsită nici o fătare în urma căutării dvs.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr
                  *ngFor="let birth of births"
                >
                  <td>{{birth.data_dmy}}</td>
                  <td>{{birth.matricolvitel}}</td>
                  <td>{{birth.nume}}</td>
                  <td>{{birth.sex_text}}</td>
                  <td>
                    <span class="d-inline-block">{{birth.mama_matricol}}</span>
                    <span class="badge text-secondary">{{birth.mama_rasa}}</span>
                    <span class="small">{{birth.mama_nume}}</span>
                  </td>
                  <td>
                    <span class="d-inline-block">{{birth.tata_matricol}}</span>
                    <span class="badge text-secondary">{{birth.tata_rasa}}</span>
                    <span class="small">{{birth.tata_nume}}</span>
                  </td>
                  <td>{{birth.rangfatare}}</td>
                  <td [ngSwitch]="birth.usurintafatare" class="small">
                    <span *ngSwitchCase="0">0 - Nu se știe</span>
                    <span *ngSwitchCase="1">1 - Ușoară fără asistență</span>
                    <span *ngSwitchCase="2">2 - Ușoară cu un anumit grad de asistență</span>
                    <span *ngSwitchCase="3">3 - Fătare dificilă</span>
                    <span *ngSwitchCase="4">4 - Operație cezariană</span>
                    <span *ngSwitchCase="5">5 - Embriotomie</span>
                  </td>
                  <td>
                    <a
                      *ngIf="birth.anexa8_id !== null"
                      href="javascript: void(0);"
                      (click)="openAnexa8(birth.anexa8_id)"
                    >{{birth.anexa8}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="birthsTableService.totalRecords > 0">
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ birthsTableService.startIndex }} la {{ birthsTableService.endIndex }} din
                {{ birthsTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="birthsLength"
                  [(page)]="birthsTableService.page"
                  [pageSize]="birthsTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-rep-fat-modal
  [data]="modalData"
  (callbackResult)="getModalResult($event)"
>
</app-rep-fat-modal>
