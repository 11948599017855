<div class="container-fluid  pt-4">

  <div class="row">
    <div class="col-sm-12">

      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>ANEXA ÎNREGISTRĂRI I.A./M.N.</th>
              <th class="text-center">Stare</th>
              <th class="text-center">Acțiuni</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let anexa of anexe3">
              <td>{{anexa.number}} / {{anexa.data_dmy}}</td>

              <td
                class="text-center"
                [ngSwitch]="anexa.status"
              >
                <span *ngSwitchCase="0">În așteptare</span>
                <span *ngSwitchCase="1">Verificare</span>
                <span *ngSwitchCase="2">Operare</span>
                <span *ngSwitchCase="3">Validat</span>
                <span *ngSwitchCase="4">Refuzat</span>
                <span *ngSwitchCase="5">Anulat</span>
                <span *ngSwitchDefault>?</span>
              </td>

              <td class="action-buttons text-center">
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-icon btn-success"
                    title="Editează"
                    (click)="openAnexa3(anexa.id)"
                  >
                    <i class="mdi mdi-square-edit-outline"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    (click)="viewAnexa3(anexa)"
                  >
                    <span class="btn-label">
                      <i class="fe-eye"></i>
                    </span>
                    Vizualizează
                  </button>

                  <button
                    type="button"
                    class="btn btn-sm btn-info"
                    (click)="downloadAnexa3(anexa)"
                  >
                    <span class="btn-label">
                      <i class="fe-download"></i>
                    </span>
                    Descarcă PDF
                  </button>

                  <button
                    *ngIf="isAdmin && anexa.nr_inregistrari == 0"
                    class="btn btn-sm btn-icon btn-danger"
                    (click)="clickStergeAnexa(anexa.id)"
                    ngbTooltip="Șterge"
                  >
                    <i class="mdi mdi-delete-forever"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>

    </div>
  </div>
</div>


<ng-template #stergeAnexaModal let-modal="close">
  <div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="modal('Cross click')">
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text text-center mb-3">
      <h3 class="confirm-text">Șterge Anexa?</h3>
    </div>

    <div class="separator"></div>
    <div class="container-fluid confirm-modal text-center">
      <button class="btn btn-warning mr-4" (click)="modal('Cross click')">
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareStergeAnexa(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>

<app-rep-fat-modal
  [data]="modalData"
  (callbackResult)="getModalResult($event)"
>
</app-rep-fat-modal>
