<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="contractsTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
              <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openAddModal()"
                  [disabled]="loadingDataSpinner"
                  *ngIf="currentRoute.includes('/exploatatie/') && permisiuneCreare"
                >
                  <i class="fe-save"></i>
                  Adaugă contract
                </button>
                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                  *ngIf="!isContabil"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #contractsTable>
            <table
              id="contracts-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="numar" (sort)="onSort($event)">
                    Număr contract
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    Status
                  </th>
                  <th sortable="rasa" (sort)="onSort($event)">
                    Rasă
                  </th>
                  <th sortable="tipcontract" (sort)="onSort($event)">
                    Tip contract
                  </th>
                  <th
                    sortable="nrfemelecuratcertificat"
                    (sort)="onSort($event)"
                  >
                    Nr. femele rasă pură
                  </th>
                  <th
                    sortable="nrmasculicuratcertificat"
                    (sort)="onSort($event)"
                  >
                    Nr. masculi rasă pură
                  </th>
                  <th sortable="nrtineretrasapura" (sort)="onSort($event)">
                    Nr. tineret rasă pură
                  </th>
                  <th sortable="nrmetis" (sort)="onSort($event)">
                    Nr. femele înregistrate în sect. suplimentară
                  </th>
                  <th
                    scope="col"
                    *ngIf="currentRoute.includes('/exploatatie/') && !isContabil"
                  >
                    Actiuni
                  </th>
                </tr>
              </thead>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="contractsTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit nici un contract.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let contract of contracts">
                  <td> {{contract.numar}} </td>
                  <td [ngSwitch]="contract.status">
                    <span *ngSwitchCase="0">Inactiv</span>
                    <span *ngSwitchCase="3">Activ</span>
                    <span *ngSwitchCase="4">Reziliat</span>
                    <span *ngSwitchCase="5">Suspendat</span>
                  </td>
                  <td> {{contract.rasa}} </td>
                  <td [ngSwitch]="contract.tipcontract">
                    <span *ngSwitchCase="1">RG</span>
                    <span *ngSwitchCase="2">CPP</span>
                  </td>
                  <td> {{contract.nrfemelecuratcertificat}} </td>
                  <td> {{contract.nrmasculicuratcertificat}} </td>
                  <td> {{contract.nrtineretrasapura}} </td>
                  <td> {{contract.nrmetis}} </td>

                  <td
                    class="action-buttons"
                    *ngIf="currentRoute.includes('/exploatatie/')"
                  >
                    <div class="btn-group">
                      <button
                        *ngIf="!isContabil"
                        class="btn btn-sm btn-icon btn-success"
                        [title]="permisiuneEditare ? 'Editează' : 'Vizualizează'"
                        (click)="openEditModal(contract.id)"
                      >
                        <i *ngIf="permisiuneEditare" class="mdi mdi-square-edit-outline"></i>
                        <i *ngIf="!permisiuneEditare" class="mdi mdi-eye-outline"></i>
                      </button>
                      <button
                        *ngIf="permisiuneCreare"
                        class="btn btn-sm btn-icon btn-danger"
                        (click)="openConfirmActionModal(contract.id)"
                        ngbTooltip="Șterge contract"
                        placement="bottom"
                      >
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="contractsTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ contractsTableService.startIndex }} la
                {{ contractsTableService.endIndex }} din
                {{ contractsTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="contractsLength"
                  [(page)]="contractsTableService.page"
                  [pageSize]="contractsTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-add-update-contract
  [addEditModalData]="addEditModalData"
  (callbackResult)="getCallbackResult($event)"
>
</app-add-update-contract>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-contract'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
