<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="operatorsTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >

               <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>


                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openAddEditOperatorModal(null)"
                >
                  <i class="fe-save"></i>
                  Adaugă operator
                </button>
                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive" #operatorsTable>
            <table
              id="operators-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="id" (sort)="onSort($event)">
                    ID
                  </th>
                  <th sortable="numeComplet" (sort)="onSort($event)">
                    NUME PRENUME
                  </th>
                  <th sortable="cod" (sort)="onSort($event)">
                    COD
                  </th>
                  <th sortable="nraut" (sort)="onSort($event)">
                    NR. AUTORIZAȚIE
                  </th>
                  <th>
                    CNP
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    STATUS
                  </th>
                  <th scope="col">
                    ACȚIUNI
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="operatorsTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit niciun operator în urma căutări dvs.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              >
              </div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner}">
                <tr tr *ngFor="let operator of operatori">
                  <td> {{operator.id}} </td>
                  <td> {{operator.numeComplet}} </td>
                  <td> {{operator.cod}} </td>
                  <td> {{operator.nraut}} </td>
                  <td> {{operator.cnp}} </td>
                  <td>
                    <span *ngIf="operator.status == 1; else inactiv">
                      Activ
                    </span>

                    <ng-template #inactiv>
                      <span>Inactiv</span>
                    </ng-template>
                  </td>
                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm btn-icon btn-success"
                        title="Editează"
                        (click)="openAddEditOperatorModal(operator)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="operatorsTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ operatorsTableService.startIndex }} la {{ operatorsTableService.endIndex }} din
                {{ operatorsTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="operatoriLength"
                  [(page)]="operatorsTableService.page"
                  [pageSize]="operatorsTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-update-operator
  [open]="openOperatorModal"
  (notifyClose)="closeOperatorModal($event)"
  (callbackResult)="getModalResult($event)"
  [operator]="operator"
>
</app-add-update-operator>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalSearchType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>
