<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="performanceTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  *ngIf="!isAutoritateJudeteana && !isContabil"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportXls()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele filtrate"
                  placement="bottom"
                >
                  <i class="far fa-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #performanceTable>
            <table
              id="performance-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="judet" (sort)="onSort($event)">
                    Județ
                  </th>
                  <th sortable="exploatatie" (sort)="onSort($event)">
                    Exploatație
                  </th>
                  <th sortable="numarmatricol" (sort)="onSort($event)">
                    Număr matricol
                  </th>
                  <th sortable="sex" (sort)="onSort($event)">
                    Sex
                  </th>
                  <th sortable="rasa" (sort)="onSort($event)">
                    Rasă
                  </th>
                  <th sortable="sectiune" (sort)="onSort($event)">
                    Secțiune
                  </th>
                  <th sortable="clasa" (sort)="onSort($event)">
                    Clasă
                  </th>
                  <th sortable="dataNastere" (sort)="onSort($event)">
                    Dată naștere
                  </th>
                  <th sortable="greutate_nastere" (sort)="onSort($event)">
                    Greutate naștere
                  </th>
                  <th sortable="greutate_200" (sort)="onSort($event)">
                    Greutate 200
                  </th>
                  <th sortable="SMZ" (sort)="onSort($event)">
                    SMZ
                  </th>
                  <th sortable="ApreciereFenotipica" (sort)="onSort($event)">
                    Apr. fenotipică
                  </th>
                  <th sortable="Status" (sort)="onSort($event)">
                    Status
                  </th>

                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="performanceTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o performanță.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let performance of performances">
                  <td> {{performance.judet}} </td>
                  <td> {{performance.exploatatie}} </td>
                  <td> {{performance.numarmatricol}} </td>
                  <td> {{performance.sex}} </td>
                  <td> {{performance.rasa}} </td>
                  <td [ngSwitch]="performance.sectiune">
                    <span *ngSwitchCase="'NEINREGISTRAT'">Neînregistrat</span>
                    <span *ngSwitchCase="'SECTIUNE_PRINCIPALA'">Secțiune principală</span>
                    <span *ngSwitchCase="'SECTIUNE_SUPLIMENTARA_A'">Secțiune suplimentară A</span>
                    <span *ngSwitchCase="'SECTIUNE_SUPLIMENTARA_B'">Secțiune suplimentară B</span>
                    <span *ngSwitchCase="'SECTIUNE_SUPLIMENTARA_C'">Secțiune suplimentară C</span>
                    <span *ngSwitchCase="'SECTIUNE_SUPLIMENTARA_D'">Secțiune suplimentară D</span>
                    <span *ngSwitchCase="'Indiferent'">Indiferent</span>
                  </td>
                  <td> {{performance.clasa}} </td>
                  <td> {{performance.dataNastere}} </td>
                  <td> {{performance.greutate_nastere}} </td>
                  <td> {{performance.greutate_200}} </td>
                  <td> {{performance.SMZ}} </td>
                  <td> {{performance.ApreciereFenotipica}} </td>
                  <td> {{performance.Status}} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="performanceTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ performanceTableService.startIndex }} la
                {{ performanceTableService.endIndex }} din
                {{ performanceTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="performancesLength"
                  [(page)]="performanceTableService.page"
                  [pageSize]="performanceTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="'performance'"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>
