import { Component, OnInit } from '@angular/core';
import { UsersTableService } from '../../../common/services/users-table.service';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UsersTableService, DecimalPipe]
})
export class UsersComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public usersTableService: UsersTableService,
  ) {
    this.tables$ = usersTableService.tables$;
    this.total$ = usersTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.usersTableService
      .getUsersAPI(this.objToAPI).subscribe((response) => {
        this.loadingDataSpinner = false;
      });
  }

  filterSearchData(event) {
    this.objToAPI.page_no = '1';
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}
