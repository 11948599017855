import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { RaportGeneralService } from '../../../common/services/raport-general.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-raport-general',
  templateUrl: './raport-general.component.html',
  styleUrls: ['./raport-general.component.scss'],
  providers: [RaportGeneralService],
  encapsulation: ViewEncapsulation.None,
})
export class RaportGeneralComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  stubUrl: string;
  titlu: string;
  intervalID: number;
  fisiere: Array<string>;
  in_curs: Array<string>;
  error: string;
  buton_dezactivat = false;
  RaportGeneralForm: UntypedFormGroup;
  REFRESH_INTERVAL = 20000;
  WAIT_AFTER_SUBMIT = 1000;
  arataPerioadaStart = true;
  arataPerioadaStop = true;
  arataRasa = true;
  arataSectiune = true;
  arataSex = true;
  arataStare = true;
  arataStareExploatatie = false;
  arataCautareExploatatie = false;
  arataLuna = false;
  arataAnul = false;
  arataMetodaCPPC = false;
  arataJudet = false;
  checkedToateJudetele = true;
  exploatatii = [];
  ani = [];
  judete = [];

  constructor(
    private raportGeneralService: RaportGeneralService,
    private helperDataService: HelperDataService,
    private router: Router,
    private store: Store<ApplicationState>,
  ) {}

  ngOnInit(): void {
    this.stubUrl = this.router.url.replace('/dashboard/', '');
    this.setup_titlu();
    this.setup_campuri();
    this.creazaForm();
    this.raportGeneralService.setupUrl(this.stubUrl);
    this.updateFileList();
    this.intervalID = window.setInterval(this.updateFileList.bind(this), this.REFRESH_INTERVAL);
  }

  setup_titlu() {
    switch (this.stubUrl) {
      case 'raport_indicatori':
        this.titlu = 'Raport Indicatori de producție și reproducție - carne';
        break;
      case 'raport_femele':
        this.titlu = 'Raport Femele - Reproducție';
        break;
      case 'raport_descendenti':
        this.titlu = 'Raport Descendenți';
        break;
      case 'raport_animale':
        this.titlu = 'Raport Animale';
        break;
      case 'raport_teste_adn':
        this.titlu = 'Raport Teste ADN';
        break;
      case 'raport_informare_lunara_cppc':
        this.titlu = 'Raport Informare Lunară CPPC';
        break;
      case 'raport_atentionari_fermier':
        this.titlu = 'Raport Atenționări Pentru Fermier';
        break;
      case 'raport_grafic_cppc':
        this.titlu = 'Raport Grafic CPPC';
        break;
      case 'raport_anz':
        this.titlu = 'Raport ANZ';
        break;
      case 'raport_coeficientul_de_izolare_reproductiva':
        this.titlu = 'Raport Coeficientul de Izolare Reproductivă';
        break;
      case 'raport_consangvinizare':
        this.titlu = 'Raport Consangvinizare';
        break;
      case 'raport_durata_medie_de_exploatare':
        this.titlu = 'Raport Durata Medie de Exploatare';
        break;
      case 'raport_exploatatii':
        this.titlu = 'Raport Exploatații';
        break;
      case 'raport_autorizatii_monta':
        this.titlu = 'Raport Autorizații Montă';
        break;
      case 'raport_date_colectate':
        this.titlu = 'Raport Date Colectate';
        break;
    }
  }

  setup_campuri() {
    switch (this.stubUrl) {
      case 'raport_indicatori':
        this.arataStare = false;
        this.arataStareExploatatie = true;
        break;
      case 'raport_femele':
        this.arataSex = false;
        break;
      case 'raport_descendenti':
        this.arataSex = false;
        break;
      case 'raport_animale':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataCautareExploatatie = true;
        break;
      case 'raport_teste_adn':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataCautareExploatatie = true;
        break;
      case 'raport_informare_lunara_cppc':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = false;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = false;
        this.arataLuna = true;
        this.arataAnul = true;
        break;
      case 'raport_atentionari_fermier':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = false;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = false;
        break;
      case 'raport_grafic_cppc':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = false;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = false;
        this.arataAnul = true;
        this.arataMetodaCPPC = true;
        this.arataJudet = true;
        break;
      case 'raport_autorizatii_monta':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = true;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = true;
        break;
      case 'raport_date_colectate':
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = false;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = false;
        this.arataAnul = true;
        break;

      default:
        this.arataPerioadaStart = false;
        this.arataPerioadaStop = false;
        this.arataRasa = false;
        this.arataSectiune = false;
        this.arataSex = false;
        this.arataStare = false;
        break;
    }
  }

  creazaForm() {
    this.RaportGeneralForm = new UntypedFormGroup({});
    this.buton_dezactivat = false;

    if (this.arataPerioadaStart) {
      this.RaportGeneralForm.addControl('perioada_start', new UntypedFormControl())
    }

    if (this.arataPerioadaStop) {
      this.RaportGeneralForm.addControl('perioada_stop', new UntypedFormControl())
    }

    if (this.arataRasa) {
      this.RaportGeneralForm.addControl('rasa', new UntypedFormControl())
    }

    if (this.arataSectiune) {
      this.RaportGeneralForm.addControl('sectiune', new UntypedFormControl())
    }

    if (this.arataSex) {
      this.RaportGeneralForm.addControl('sex', new UntypedFormControl())
    }

    if (this.arataStare) {
      this.RaportGeneralForm.addControl('stare', new UntypedFormControl())
    }

    if (this.arataStareExploatatie) {
      this.RaportGeneralForm.addControl('stare_exploatatie', new UntypedFormControl())
    }

    if (this.arataCautareExploatatie) {
      this.RaportGeneralForm.addControl('exploatatie_id', new UntypedFormControl())
    }

    if (this.arataLuna) {
      this.RaportGeneralForm.addControl('luna', new UntypedFormControl())
    }

    if (this.arataAnul) {
      this.RaportGeneralForm.addControl('anul', new UntypedFormControl())
      this.set_ani();
    }

    if (this.arataMetodaCPPC) {
      this.RaportGeneralForm.addControl('metoda_cppc', new UntypedFormControl('contract'))
    }

    if (this.arataJudet) {
      this.checkedToateJudetele = true;
      this.RaportGeneralForm.addControl('judete', new UntypedFormArray([]));
      this.judete = [];
      this.store
        .pipe(select(getAppCities))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          if (!response) {
            return;
          }

          for (let elem of response) {
            this.judete.push({ ...elem, checked: true });
          }
        });
    }
  }

  set_ani() {
    let currentYear = new Date().getFullYear();

    if (this.stubUrl === 'raport_informare_lunara_cppc') {
      for (let i = 2020; i <= currentYear; i++) {
        this.ani.push(i);
      }
    } else if (this.stubUrl === 'raport_grafic_cppc') {
      for (let i = 2020; i <= currentYear + 3; i++) {
        this.ani.push(i);
      }

      this.RaportGeneralForm.controls.anul.setValue(currentYear);
    } else if (this.stubUrl === 'raport_date_colectate') {
      for (let i = 2020; i <= currentYear; i++) {
        this.ani.push(i);
      }

      this.RaportGeneralForm.controls.anul.setValue(currentYear - 1);
    }
  }

  updateFileList() {
    this.raportGeneralService.listaFisiereAPI().subscribe((response) => {
      this.fisiere = response;
    });

    this.raportGeneralService.inCursAPI().subscribe((response) => {
      this.in_curs = response;

      if (this.buton_dezactivat && (!response || response.length === 0)) {
        this.creazaForm();
      }
    });
  }

  onStartDateChanged(event) {
    if (this.RaportGeneralForm) {
      this.RaportGeneralForm.controls.perioada_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.RaportGeneralForm) {
      this.RaportGeneralForm.controls.perioada_stop.setValue(event);
    }
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.exploatatii = [];

      return;
    }

    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        },
        error: (errors) => {
          this.error = errors.error;
        }
      });
  }

  creazaRaport() {
    this.error = '';
    this.raportGeneralService.creazaRaportAPI(this.RaportGeneralForm.value)
      .subscribe({
        next: () => {
          this.buton_dezactivat = true;
          setTimeout(this.updateFileList.bind(this), this.WAIT_AFTER_SUBMIT);
        },
        error: (errors) => {
          this.error = errors.error;
        }
      });
  }

  downloadRaport(fisier: string) {
    this.raportGeneralService.downloadRaportAPI(fisier)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/xls' });
        this.helperDataService.simulateDownload(blob, fisier);
        this.updateFileList();
      });
  }

  stergeRaport(fisier: string) {
    this.raportGeneralService.stergeRaportAPI(fisier)
      .subscribe(() => {
        this.updateFileList();
      });
  }

  clickJudet(event, judet) {
    judet.checked = event.target.checked;
    this.checkedToateJudetele = true;
    let judeteArray = this.RaportGeneralForm.controls.judete as UntypedFormArray;
    judeteArray.clear();

    for (let elem of this.judete) {
      if (elem.checked) {
        judeteArray.push(new UntypedFormControl(elem.name));
      } else {
        this.checkedToateJudetele = false;
      }
    }

    if (this.checkedToateJudetele) {
      judeteArray.clear();
    }
  }

  toateJudetele(event) {
    this.checkedToateJudetele = event.target.checked;
    let judeteArray = this.RaportGeneralForm.controls.judete as UntypedFormArray;
    judeteArray.clear();

    for (let elem of this.judete) {
      elem.checked = event.target.checked;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();

    if (this.intervalID) {
      window.clearInterval(this.intervalID)
    }
  }
}
