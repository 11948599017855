<div class="card" id="pozeAnimal" [formGroup]="animalForm">
  <h3 class="card-header">Poze Animal</h3>
  <div class="card-body">
    <div
      formArrayName="poze"
      *ngFor="let item of formData.controls; let i = index; let last = last; let first = first"
    >
      <div
        *ngIf="!first"
        class="separator mt-2 mb-2">
      </div>

      <div class="d-flex align-items-center" [formGroupName]="i">
        <div *ngIf="imageUrls[i]" class="mr-3">
          <img src="{{ imageUrls[i] }}" class="animalImage">
        </div>

        <div
          *ngIf="formData.controls[i].value.uploaded_file_name"
        >
          <div class="mb-3">
            <button
              type="button"
              class="btn p-0 text-blue font-weight-bold"
              (click)="downloadImage(i)"
            >
              <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
              {{ formData.controls[i].value.uploaded_file_name }}
            </button>
          </div>

          <div class="mb-3">
            <button
              *ngIf="!disableAllFields"
              type="button"
              class="btn btn-sm btn-info mr-3"
              (click)="setDefaultPicture(i)"
              [ngClass]="{'disabled': formData.controls[i].value.filetype === 'default'}"
            >
              <i class="fas fa-bookmark mr-1"></i>
              <span *ngIf="formData.controls[i].value.filetype !== 'default'">Seteaza implicit</span>
              <span *ngIf="formData.controls[i].value.filetype === 'default'">Poza implicita</span>
            </button>

            <button
              *ngIf="arataButoanePoza && !disableAllFields"
              type="button"
              class="btn btn-sm btn-danger"
              [ngClass]="{ disabled : disableAllFields }"
              (click)="deleteImage(i)"
            >
              <i class="fas fa-minus-circle"></i>
              Șterge poza
            </button>
          </div>
        </div>

      </div>
    </div>

    <div
      *ngIf="arataButoanePoza && !disableAllFields"
      class="mt-2 mb-3"
    >
      <div *ngIf="formData.controls.length > 0" class="separator mb-2"></div>

      <app-upload-fisier
        [multiple]="true"
        (ApiResponse)="uploadServerResponse($event)"
      >
      </app-upload-fisier>
    </div>
  </div>
</div>
